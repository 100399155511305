import { ComponentType } from "react";

import clsx from "clsx";

import { Column, Row } from "@/components/containers";
import { Chip } from "@/components/displays";
import { Button } from "@/components/inputs";
import { TextArea } from "@/components/inputs/TextArea";

import { BeingIn } from "./BeingIn";
import { BoughtTooMuch } from "./BoughtTooMuch";
import { RetentionContentProps } from "./common";
import { GoingOut } from "./GoingOut";
import { HighPrice } from "./HighPrice";
import styles from "./RetentionContents.module.scss";
import { Substitute } from "./Substitute";
import { Weariness } from "./Weariness";
import { SkipModalEvent } from "../types";

interface RetentionContentsProps {
  answerIndex: string;
  onAction: (event: SkipModalEvent) => void;
  onChangeOtherReason: (reason: string) => void;
}

const RetentionContentsMap: Record<
  string,
  { Component: ComponentType<RetentionContentProps>; title: string | null; showSkipButton: boolean }
> = {
  "1": { Component: GoingOut, title: "こんな時におすすめ", showSkipButton: true }, // 外出が増えたため
  "2": { Component: BeingIn, title: "在宅におすすめ", showSkipButton: true }, // 在宅が増えたため
  "3": { Component: HighPrice, title: "実はコスパ抜群のBASE FOOD", showSkipButton: false }, // 価格が高いため
  "4": { Component: BoughtTooMuch, title: null, showSkipButton: false }, // 購入しすぎたため
  "5": { Component: Weariness, title: null, showSkipButton: true }, // 飽きたため
  "6": { Component: Substitute, title: "BASE FOODの推しポイント", showSkipButton: false }, // 代替品を見つけたため
};

const otherReasonIndex = "7"; // その他の理由

export function RetentionContents({
  answerIndex,
  onAction,
  onChangeOtherReason,
}: RetentionContentsProps) {
  if (answerIndex === otherReasonIndex) {
    return (
      <TextArea
        placeholder="理由を教えてください"
        maxLength={250}
        className={styles.otherReason}
        onChange={(e) => onChangeOtherReason(e.target.value)}
      />
    );
  }
  const content = RetentionContentsMap[answerIndex];
  if (!content) return null;
  const { Component, title, showSkipButton } = content;
  return (
    <Column className={styles.root}>
      <section className={clsx(styles.container, "bg__gray")}>
        {title && (
          <Chip color="red" className={styles.title}>
            {title}
          </Chip>
        )}
        <Component onAction={onAction} />
      </section>
      {showSkipButton && (
        <Row className={styles.bottomActions}>
          <Button variants="yellow" rounded onClick={() => onAction({ type: "cancel" })}>
            スキップせずに続ける
          </Button>
        </Row>
      )}
    </Column>
  );
}
