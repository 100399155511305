"use client";
import { useMemo } from "react";

import clsx from "clsx";

import { Column, Row } from "@/components/containers";
import {
  frozenTemperatureProductsNames,
  normalTemperatureProductsNames,
} from "@/models/product/consts";
import { useGetParsedProductsByNames } from "@/queries";
import { sumBy } from "@/utils/array";

import styles from "./ProductTotalCounts.module.scss";
import { CartProduct, Temperature } from "../../types";
import { groupProductsByCategory } from "../helper";

interface ProductTotalCountsProps {
  products: CartProduct[];
  temperature: Temperature;
}

const totalCountCategories = [
  { title: "BREAD", category: "bread", temperature: "normal" },
  { title: "Pancake", category: "pancake", temperature: "normal" },
  { title: "PASTA", category: "pasta", temperature: "normal" },
  { title: "Cookies", category: "cookie", temperature: "normal" },
  { title: "PASTA", category: "deliPasta", temperature: "frozen" },
] as const;

export function ProductTotalCounts({
  products,
  temperature,
}: ProductTotalCountsProps): React.ReactNode {
  const productNames =
    temperature === "normal" ? normalTemperatureProductsNames : frozenTemperatureProductsNames;
  const { data: allProducts } = useGetParsedProductsByNames({ names: [...productNames] });
  const productsByCategory = useMemo(() => {
    const productsWithName = products.map((productWithoutName) => {
      // innerSubscription?.productsのnameプロパティがundefinedなので、productsからnameを取得して追加する
      const product = allProducts.find((p) => p.variantId === productWithoutName.variantId);
      return {
        ...product,
        quantity: productWithoutName.quantity,
        name: product?.name ?? "",
      };
    });
    return groupProductsByCategory(productsWithName ?? []);
  }, [products, allProducts]);

  const filteredCategories = useMemo(
    () => totalCountCategories.filter((category) => category.temperature === temperature),
    [temperature]
  );

  return (
    <Row className={styles.amount}>
      {filteredCategories.map((countItem) => {
        return (
          <Column key={`${countItem.category}-${countItem.title}`}>
            <p className={clsx("text__s", "text__bold")}>{countItem.title}</p>
            <p className={clsx("text__l", "text__bold")}>
              {sumBy(productsByCategory[countItem.category], "quantity")}
              <span className="text__s">袋</span>
            </p>
          </Column>
        );
      })}
    </Row>
  );
}
