import clsx from "clsx";
import urlJoin from "url-join";

import { NewsPostModel } from "@/models/newsPost/type";

import styles from "./NewsSection.module.scss";

interface NewsSectionProps {
  newsPosts: NewsPostModel[];
}

export function NewsSection({ newsPosts }: NewsSectionProps): React.ReactNode {
  return (
    <div className={clsx("bg__white mg__bottom__m", styles.section)}>
      <div className={styles.flex}>
        <h3 className="text__l text__bold">お知らせ</h3>
      </div>

      {newsPosts.length > 0 && (
        <ul>
          {newsPosts.map((post) => (
            <li className={styles.newsListItem} key={post.id}>
              <a
                href={urlJoin(process.env.NEXT_PUBLIC_FRONT_BASE_URL ?? "", "news", `${post.id}`)}
                target="_blank"
                className={styles.news}
              >
                <span className={clsx(styles.tag, styles.yellow)}>{post.category}</span>
                <span className="text__s text__bold">{post.date}</span>
                <p className="text__m mg__top__s">{post.title}</p>
                <i className={clsx("fas fa-angle-right", styles.angle)}></i>
              </a>
            </li>
          ))}
        </ul>
      )}

      <div className="text__right pd__top__s">
        <a
          href={`${process.env.NEXT_PUBLIC_FRONT_BASE_URL}news`}
          target="_blank"
          className={styles.link}
        >
          お知らせ一覧↗
        </a>
      </div>
    </div>
  );
}
