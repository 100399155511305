"use client";

import { useEffect } from "react";

import { detectDataChangeFunctions, useModalState } from "@/utils/hooks";

import { MyPageDiscardConfirmModal } from "./MyPageDiscardConfirmModal";

export function MyPageDiscardConfirm(): React.ReactNode {
  const [isDestroyConfirmModalOpen, openDestroyConfirmModal, closeDestroyConfirmModal] =
    useModalState<boolean>();

  useEffect(() => {
    detectDataChangeFunctions.registerOpenConfirmModal(openDestroyConfirmModal);
    return () => {
      detectDataChangeFunctions.unregisterOpenConfirmModal();
    };
  }, [openDestroyConfirmModal]);

  return (
    <MyPageDiscardConfirmModal
      open={isDestroyConfirmModalOpen}
      onClose={() => closeDestroyConfirmModal(false)}
      onDiscard={() => closeDestroyConfirmModal(true)}
    />
  );
}
