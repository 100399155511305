import clsx from "clsx";

import { ImageLink, ImageLinkItem } from "./ImageLinkItem";
import styles from "./ImageLinkList.module.scss";

interface ImageLinkListProps {
  images: ImageLink[];
}

export function ImageLinkList({ images }: ImageLinkListProps): React.ReactNode {
  return (
    <div className={clsx(styles.container, "mg__bottom__s")}>
      <ul className={styles.wrapper}>
        {images.map((image, index) => (
          <ImageLinkItem key={index} {...image} />
        ))}
      </ul>
    </div>
  );
}
