import { Column, Row } from "@/components/containers";
import { numberFormat } from "@/utils";

import styles from "./MileInTradeInfo.module.scss";

export interface MileInTradeInfoProps {
  mileInTrade: number;
}

export function MileInTradeInfo({ mileInTrade }: MileInTradeInfoProps): React.ReactNode {
  return (
    <Column gap={10} className={styles.container}>
      <Row align="center">
        <div className={styles.title}>交換予定マイル</div>
        <div className={styles.amount}>
          {mileInTrade ? `-${numberFormat(mileInTrade)}` : mileInTrade}
        </div>
      </Row>
      <div className={styles.description}>
        ※ギフトとの交換に利用されるマイルです。ギフトが同梱される継続コースの注文が確定した時点で、マイル消費も確定します。
      </div>
    </Column>
  );
}
