import React from "react";

import clsx from "clsx";

import { Column } from "@/components/containers";
import { Image } from "@/components/displays";

import styles from "./BeingIn.module.scss";

export function BeingIn() {
  return (
    <Column className={styles.root}>
      <p className={clsx("text__l", "text__bold", "text__center", "mg__top__m", "mg__bottom__m")}>
        おうちでかんたん・おいしい
        <br />
        レシピをご紹介！
      </p>
      <p className={clsx("text__m", "mg__bottom__m")}>
        在宅時間が増えたらアレンジ自在のBASE BREADミニ食パンがおすすめ！
      </p>
      <Image
        className={styles.image}
        containerClassName={styles.imageContainer}
        aspectRatio="503 / 757"
        alt="レシピ"
        src="https://asset.basefood.co.jp/images/ph/ph_skip_reason_recipe_01.png?0222"
      />
    </Column>
  );
}
