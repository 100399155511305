"use client";
import clsx from "clsx";

import { Row } from "@/components/containers";
import { Accordion } from "@/components/displays";

import styles from "./AdditionalProductsSection.module.scss";
import { CartProduct } from "../../../types";
import { SubscriptionProduct } from "../../helper";
import { SubscriptionProductItem } from "../../SubscriptionProductItem";

interface AdditionalProductsSectionProps {
  title: React.ReactNode;
  products: SubscriptionProduct[];
  deliveryDate: Date | null;
  isFirstSubscriptionOrder: boolean;
  pastDiscountRate: boolean;
  isNew: boolean;
  onChangeQuantity?: (products: CartProduct[]) => void;
  open: boolean;
  onOpenChanged: (open: boolean) => void;
}

export function AdditionalProductsSection({
  title,
  products,
  deliveryDate,
  isFirstSubscriptionOrder,
  pastDiscountRate,
  isNew,
  onChangeQuantity,
  open,
  onOpenChanged,
}: AdditionalProductsSectionProps): React.ReactNode {
  return (
    <Accordion
      className={styles.root}
      open={open}
      onOpenChanged={onOpenChanged}
      title={
        <Row className={styles.title}>
          <span>
            {title} <span>を追加する</span>
          </span>
          {isNew && (
            <span className={clsx("text__m", "text__red", "text__bold", styles.newTag)}>NEW</span>
          )}
        </Row>
      }
      openIcon={<i className="fas fa-angle-down" />}
      closeIcon={<i className="fas fa-angle-up" />}
    >
      {products.map((product) => {
        return (
          <SubscriptionProductItem
            key={product.variantId}
            product={product}
            deliveryDate={deliveryDate}
            isFirstSubscriptionOrder={isFirstSubscriptionOrder}
            pastDiscountRate={pastDiscountRate}
            onChangeQuantity={
              onChangeQuantity
                ? (quantity) => onChangeQuantity([{ variantId: product.variantId, quantity }])
                : undefined
            }
          />
        );
      })}
    </Accordion>
  );
}
