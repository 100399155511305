"use client";

import { redirect } from "next/navigation";

import { useParsedGetSubscription } from "@/queries";

export function StatusRedirectManager(): React.ReactNode {
  const {
    data: { subscription, freezeSubscription },
  } = useParsedGetSubscription();

  if (subscription == null && freezeSubscription == null) {
    // 定期購入がない場合は解約する必要がない
    redirect("/mypage");
  }

  return null;
}
