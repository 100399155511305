/**
 * PurchaseHistoryTableのhelpers.tsと同じだけど、型が違うのでコピーして作成。
 * TODO：　型の定義を共通化したら、helpers.tsを共通化する
 */
import { OrderModel } from "@/models/order/type";
import { ProductModel } from "@/models/product/type";

export function getProductPrice(product: ProductModel, order: OrderModel): number | undefined {
  if (product.subscription === false) {
    return product.normalTotalPrice;
  } else if (product.isBrandNewDiscount) {
    return product.productBrandNewDiscountTotalPrice;
  } else if (order.subscriptionDiscountRate === 20) {
    return product.firstSubscriptionTotalPrice;
  } else if (order.subscriptionDiscountRate === 10) {
    return product.afterSecondSubscriptionTotalPrice;
  } else {
    return product.normalTotalPrice;
  }
}

export function isDaibiki(order: OrderModel): boolean {
  return !order.isShowCashDeliveryFee && order.paymentMethod === "daibiki";
}

export function getPaymentAmount(order: OrderModel): number {
  let amount = 0;
  if (isDaibiki(order)) {
    amount = Number(order.totalPrice);
  } else {
    amount = Number(order.totalPrice) + Number(order.totalPriceCashDeliveryFee);
  }

  return amount;
}
