import { forwardRef } from "react";

import clsx from "clsx";

import styles from "./TextArea.module.scss";

export type BaseProps = React.ComponentProps<"textarea">;
export interface TextAreaProps extends BaseProps {
  error?: boolean;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(function TextArea(
  { className, error, ...rest },
  ref
): React.ReactNode {
  return (
    <textarea {...rest} className={clsx(error && styles.error, styles.root, className)} ref={ref} />
  );
});
