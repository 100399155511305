import { MileLabelType } from "@/generated/open-api/schemas";

/**
 * @see https://www.notion.so/RN-01aa9dd502054d7792eb823c9d2f9504?pvs=4#ab671e378e2b407c9467b34b403d5333
 */
const MileHistoryTypeInfoMap: Record<MileLabelType, { isAddition: boolean; typeText: string }> = {
  [MileLabelType.ADDBYADMIN]: {
    isAddition: true,
    typeText: "付与",
  },
  [MileLabelType.INITIAL]: {
    isAddition: true,
    typeText: "獲得",
  },
  [MileLabelType.INVALID]: {
    isAddition: false,
    typeText: "失効",
  },
  [MileLabelType.INVITE]: {
    isAddition: true,
    typeText: "獲得",
  },
  [MileLabelType.PURCHASE]: {
    isAddition: true,
    typeText: "獲得",
  },
  [MileLabelType.PURCHASE_FREEZE]: {
    isAddition: true,
    typeText: "獲得",
  },
  [MileLabelType.SUBBYADMIN]: {
    isAddition: false,
    typeText: "失効",
  },
  [MileLabelType.GIFT_TRADE]: {
    isAddition: false,
    typeText: "利用",
  },
  [MileLabelType.SUMMARY_ON_RENEW]: {
    isAddition: true,
    typeText: "獲得",
  },
};

export function getMileHistoryTypeInfo(labelType: MileLabelType) {
  const typeInfo = MileHistoryTypeInfoMap[labelType];

  return {
    ...typeInfo,
    sign: typeInfo.isAddition ? "+" : "",
  };
}
