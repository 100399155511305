"use client";
import { useEffect, useMemo } from "react";

import clsx from "clsx";
import { addDays, format, parse } from "date-fns";
import { ja } from "date-fns/locale";
import { deepEqual } from "fast-equals";
import {
  Controller as RHFController,
  ControllerProps,
  FormProvider,
  useForm,
} from "react-hook-form";

import { Column, Row } from "@/components/containers";
import { TooltipIcon } from "@/components/displays";
import { Button, Checkbox, DatePicker, Selector } from "@/components/inputs";
import { OKIHAI_MAX_PRICE } from "@/configs/system";
import {
  DeliveryReceiveOption,
  deliveryReceiveOptionLabels,
  deliveryTimeZoneLabels,
} from "@/models/delivery/consts";
import { PaymentMethod } from "@/models/payment/consts";
import { SubscriptionModel } from "@/models/subscription/type";
import { useParsedGetValidDates } from "@/queries/order/useParsedGetValidDates";
import { formatCurrency } from "@/utils/currency";
import { objectEntries } from "@/utils/object";
import { useOnChange } from "@/utils/zod";

import styles from "./DeliveryScheduleForm.module.scss";
import { DeliveryScheduleFormSchemaValue } from "./schema";
import { Temperature } from "../../types";

const deliveryTimeOptions = objectEntries(deliveryTimeZoneLabels).map(([value, label]) => ({
  value,
  label,
}));

const receiveOptions = objectEntries(deliveryReceiveOptionLabels).map(([value, label]) => ({
  value,
  label,
}));

interface DeliveryScheduleFormProps {
  subscription: SubscriptionModel;
  values?: Partial<DeliveryScheduleFormSchemaValue>;
  onChange: (values: Partial<DeliveryScheduleFormSchemaValue>) => void;
  onSkip: (() => void) | undefined;
  paymentMethod: PaymentMethod | undefined;
  canEdit?: boolean;
  temperature: Temperature;
  warningMessages: string[];
  cartTotalPrice: number;
}

export function DeliveryScheduleForm({
  subscription,
  values,
  onChange,
  onSkip,
  paymentMethod,
  canEdit,
  temperature,
  warningMessages,
  cartTotalPrice,
}: DeliveryScheduleFormProps): React.ReactNode {
  const { data } = useParsedGetValidDates();
  const [minDate, maxDate] = useMemo(() => {
    if (!data.validDates.length) return [undefined, undefined];
    const last = data.validDates.length - 1;
    const minDate = parse(data.validDates[0].value, "yyyy/MM/dd", new Date());
    const maxDate = parse(data.validDates[last].value, "yyyy/MM/dd", new Date());
    return [minDate, maxDate] as const;
  }, [data.validDates]);

  const formContext = useForm<DeliveryScheduleFormSchemaValue>({
    defaultValues: values,
  });

  useEffect(() => {
    const { getValues, reset } = formContext;
    const innerValues = getValues();
    if (!deepEqual(innerValues, values)) {
      reset(values);
    }
  }, [formContext, values]);

  useEffect(() => {
    // 合計金額がOKIHAI_MAX_PRICE以上の場合、受取方法を対面受取に強制変更する
    if (
      cartTotalPrice >= OKIHAI_MAX_PRICE &&
      values?.deliveryReceiveOption !== DeliveryReceiveOption.FaceToFace
    ) {
      const { setValue } = formContext;
      setValue("deliveryReceiveOption", DeliveryReceiveOption.FaceToFace);
    }
  }, [cartTotalPrice, formContext, values?.deliveryReceiveOption]);

  useOnChange<DeliveryScheduleFormSchemaValue>(onChange, formContext);

  const [arrivalTimeFrom, arrivalTimeTo] = useMemo(() => {
    if (!subscription) {
      return ["", ""] as const;
    }
    const toDate = parse(subscription.calcNextOrderArrivalDate, "yyyy/MM/dd", new Date());
    const fromDate = addDays(toDate, -3);
    return [
      format(fromDate, "yyyy/MM/dd(E)", { locale: ja }),
      format(toDate, "yyyy/MM/dd(E)", { locale: ja }),
    ] as const;
  }, [subscription]);

  /**
   * 常温であり、代引き以外の支払い方法である
   */
  const normalTempPrepaid = temperature === "normal" && paymentMethod !== PaymentMethod.daibiki;

  /**
   * 受取方法を変更できない条件
   * - 合計金額がOKIHAI_MAX_PRICE以上
   * - 冷凍である
   * - 代引き
   * - order変更不可能である（ MyPageSubscription/helper.ts/getCanChangeOrder()）
   */
  const isReceiveOptionDisabled =
    cartTotalPrice >= OKIHAI_MAX_PRICE || !normalTempPrepaid || !canEdit;

  return (
    <FormProvider {...formContext}>
      <Column className={styles.fieldSection}>
        <Row className={styles.fieldLabel}>
          <p>次回のご注文</p>
          <span className={clsx("text__s", "text__gray__dark")}>
            {subscription?.nextOrderFixDate} に注文確定
          </span>
        </Row>
        <Column>
          <Controller
            name="deliveryDate"
            render={({ field }) => (
              <DatePicker
                disabled={!canEdit}
                className={styles.datePicker}
                minDate={minDate}
                maxDate={maxDate}
                {...field}
              />
            )}
          />
          <Row>
            <Controller
              name="deliveryTimezone"
              render={({ field: { ref, ...field } }) => (
                <Selector
                  className={styles.timeZoneSelector}
                  innerRef={ref}
                  disabled={!canEdit}
                  options={deliveryTimeOptions}
                  {...field}
                />
              )}
            />
            {onSkip && (
              <Button
                variants="gray"
                className={styles.skipButton}
                // canBackward2Week: 2ヶ月後の2週間前までスキップ可能
                disabled={!canEdit || !subscription?.skipInfo.canBackward2Week}
                onClick={onSkip}
              >
                スキップ
              </Button>
            )}
          </Row>
        </Column>
        <Column>
          {warningMessages.map((message) => (
            <p key={message} className={clsx("text__s", "text__red", "mg__top__s")}>
              {message}
            </p>
          ))}
        </Column>
        {subscription.isOrderFixDateChangedByInventry && (
          <Column className={clsx("bg__gray mg__top__s", styles.warningNote)}>
            <p className="text__s text__red">
              お届け予定日に
              {subscription.calcNextOrderArrivalDate}
              をご指定の場合は、出荷作業の都合上、注文確定を「お届け予定日の5日前」とさせていただいております。何卒ご了承ください。
            </p>
          </Column>
        )}
      </Column>
      <Column className={styles.fieldSection}>
        <Row className={styles.fieldLabel}>
          <p>
            受取方法：
            {!normalTempPrepaid && deliveryReceiveOptionLabels[DeliveryReceiveOption.FaceToFace]}
          </p>
        </Row>
        {normalTempPrepaid && (
          <>
            <div className={clsx("bg__gray mg__bottom__s", styles.warningNote)}>
              <p className="text__s">
                置き配を利用される場合は、風味の劣化を防ぐため、高温となる場所を避け、お早めにお受け取りください。
              </p>
            </div>
            <Controller
              name="deliveryReceiveOption"
              render={({ field: { ref, ...field } }) => (
                <Selector
                  innerRef={ref}
                  disabled={isReceiveOptionDisabled}
                  options={receiveOptions}
                  {...field}
                />
              )}
            />
          </>
        )}
        {cartTotalPrice >= OKIHAI_MAX_PRICE && normalTempPrepaid && (
          <p className="text__s text__gray__dark mg__top__s">
            合計金額が{formatCurrency(OKIHAI_MAX_PRICE)}(税込)以上のため置き配が選択できません。
          </p>
        )}
      </Column>
      {temperature === "normal" && (
        <Column className={styles.fieldSection}>
          <Controller
            name="isFastDelivery"
            render={({ field: { value, ...field } }) => (
              <Checkbox checked={value} disabled={!canEdit} {...field}>
                <Row>
                  出荷から最短でお届け
                  <TooltipIcon
                    tooltipCloseButtonText="OK"
                    tooltips={
                      <>
                        <p className="pd__bottom__m text__m">
                          商品の出荷後、お届け予定日に関わらず最短でお届けする設定です。
                        </p>

                        <p className="text__m">
                          次回お届け日の目安：
                          <br />
                        </p>
                        <p className="pd__bottom__m text__bold">
                          {arrivalTimeFrom} ~ {arrivalTimeTo}
                        </p>
                        <p className="text__s">
                          ※配送業者での営業所管理をせずにお届けします。
                          <br />
                          ※次回のご注文以降も同様の設定になります。
                        </p>
                      </>
                    }
                    pcTooltips={`商品の出荷後、お届け予定日に関わらず最短でお届けする設定です。

                   次回お届け日の目安：
                   ${arrivalTimeFrom} ~ ${arrivalTimeTo}

                   ※配送業者での営業所管理をせずにお届けします。
                   ※次回のご注文以降も同様の設定になります。`}
                  />
                </Row>
              </Checkbox>
            )}
          />
        </Column>
      )}
    </FormProvider>
  );
}

function Controller<Name extends keyof DeliveryScheduleFormSchemaValue>(
  props: ControllerProps<DeliveryScheduleFormSchemaValue, Name>
) {
  return <RHFController {...props} />;
}
