import dynamic from "next/dynamic";
import { FadeLoader } from "react-spinners";

export * from "./LogoutButton";

export const LogoutButton = dynamic(
  () => import("./LogoutButton").then((mod) => mod.LogoutButton),
  {
    loading: () => <FadeLoader loading={true} />,
    ssr: false,
  }
);
