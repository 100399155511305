import { useCallback, useMemo, useState } from "react";

import clsx from "clsx";

import { LinkButton } from "@/components/buttons";
import { TooltipIcon } from "@/components/displays";
import { ExclamationIcon, PointIcon } from "@/components/icons";
import { useParsedGetPoints } from "@/queries";
import { numberFormat } from "@/utils";

import { isExpireDateTwoMonth } from "./helpers";
import styles from "./PointBox.module.scss";

interface PointBoxProps {}

export function PointBox({}: PointBoxProps): React.ReactNode {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = useCallback(() => setIsOpen((prev) => !prev), []);

  const { data } = useParsedGetPoints();

  const showPointExpiryDate = useMemo(
    () => !isOpen && data.expiryPoints.length > 0 && isExpireDateTwoMonth(data.expiryDate),
    [data.expiryDate, data.expiryPoints.length, isOpen]
  );

  return (
    <div className={clsx("bg__white mg__bottom__m", styles.container)}>
      <div className={clsx(styles.point)}>
        <PointIcon />
        <div className={styles.title}>
          <p className="text__m text__bold">ポイント</p>
          <TooltipIcon
            className={styles.tooltipIcon}
            tooltipTitle="ポイントとは？"
            tooltipCloseButtonText="OK"
            tooltips={
              <p className="text__m">
                1ポイント=1円として、BASE FOOD公式サイトでの商品購入時に利用できます。
              </p>
            }
            pcTooltips="1ポイント=1円として、BASE FOOD公式サイトでの商品購入時に利用できます。"
          />
        </div>
        <div className={styles.toggle} onClick={toggleOpen}>
          <p className="text__xl text__bold">
            {numberFormat(data.total)}
            <span className="text__l">pt</span>
            <i className={clsx("fas text__l", isOpen ? "fa-angle-up" : "fa-angle-down")}></i>
          </p>
        </div>
      </div>
      {showPointExpiryDate && (
        <div className={clsx("bg__red mg__top__m", styles.expiry)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="10"
            viewBox="0 0 20 10"
            fill="none"
            className={styles.angle}
          >
            <path
              d="M0 10L8.58579 1.41421C9.36684 0.633165 10.6332 0.633163 11.4142 1.41421L20 10H0Z"
              fill="#FF6478"
            />
          </svg>
          <p className="text__white text__s text__bold text__center">
            <ExclamationIcon className={styles.exclamation} />
            {data.expiryDate}に失効するポイントが{numberFormat(data.expiryPoints[0].expiryPoint)}
            ptあります
          </p>
        </div>
      )}

      {isOpen && (
        <div className="pd__top__m">
          <div className={clsx("bg__gray mg__top__m mg__bottom__s", styles.expiry)}>
            <p className="text__s text__bold mg__bottom__s">ポイントの有効期限</p>
            {data.expiryPoints.length === 0 ? (
              <p className="text__s">利用可能なポイントがありません</p>
            ) : (
              <ul>
                {data.expiryPoints.map((point, index) => (
                  <li className={styles.expiryItem} key={index}>
                    <p
                      className={clsx(
                        "text__s",
                        isExpireDateTwoMonth(point.expiryDate) && "text__red"
                      )}
                    >
                      {point.expiryDate}まで
                    </p>
                    <p
                      className={clsx(
                        "text__m text__bold",
                        isExpireDateTwoMonth(point.expiryDate) && "text__red"
                      )}
                    >
                      {numberFormat(point.expiryPoint)}
                      <span className="text__s">pt</span>
                    </p>
                  </li>
                ))}
              </ul>
            )}
          </div>{" "}
        </div>
      )}

      <div className="text__right mg__top__s">
        <LinkButton href="/invite" noMargin size="mini" color="gray">
          ポイントを増やすには
        </LinkButton>
      </div>
    </div>
  );
}
