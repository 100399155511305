import { PropsWithChildren } from "react";

import clsx from "clsx";

import { Modal, ModalProps } from "@/components/feedbacks";

import styles from "./MyPageModal.module.scss";

export interface MyPageModalProps
  extends PropsWithChildren<Omit<ModalProps, "displayCloseButton">> {
  fullWidthFooter?: boolean;
}

export function MyPageModal({
  footer,
  fullWidthFooter,
  className,
  ...props
}: MyPageModalProps): React.ReactNode {
  return (
    <Modal
      {...props}
      bodyClassName={styles.modalBody}
      footer={footer && <div className={clsx(fullWidthFooter && styles.fullWidth)}>{footer}</div>}
      displayCloseButton={false}
      className={clsx(styles.container, className)}
    />
  );
}
