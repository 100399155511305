"use client";

import { useCallback, useState } from "react";

import clsx from "clsx";

import { Paper } from "@/components/displays";
import { usePrefetchCallbacks } from "@/utils/hooks";

import styles from "./MyPagePurchaseHistory.module.scss";
import { PointHistoryTable } from "./PointHistoryTable";
import { PurchaseHistoryTable } from "./PurchaseHistoryTable";

export function MyPagePurchaseHistory(): React.ReactNode {
  const [activeTab, setActiveTab] = useState<"purchase" | "point">("purchase");
  const { prefetchOrdersCallback, prefetchPointsCallback } = usePrefetchCallbacks();

  const handleTabChange = useCallback(
    (tab: "purchase" | "point") => () => {
      setActiveTab(tab);
    },
    []
  );

  return (
    <Paper padding="fixed40" className={clsx("bg__white", styles.container)}>
      <div className="text__m text__center">
        <ul className={styles.tabs}>
          <li
            className={clsx(
              activeTab === "purchase" && "text__yellow text__bold",
              activeTab === "purchase" && styles.active
            )}
            onClick={handleTabChange("purchase")}
            onMouseEnter={prefetchOrdersCallback({ page: 1 })}
            onFocus={prefetchOrdersCallback({ page: 1 })}
          >
            注文
          </li>
          <li
            className={clsx(
              activeTab === "point" && "text__yellow text__bold",
              activeTab === "point" && styles.active
            )}
            onClick={handleTabChange("point")}
            onMouseEnter={prefetchPointsCallback({ page: 1 })}
            onFocus={prefetchPointsCallback({ page: 1 })}
          >
            ポイント
          </li>
        </ul>
      </div>
      {activeTab === "purchase" ? <PurchaseHistoryTable /> : <PointHistoryTable />}
      <p className="text__s mg__top__s">
        ※一部、過去の注文が表示されないことがあります。詳細を確認したい場合、お問い合わせください。
      </p>
      <p className="text__s mg__top__s">
        2022/05/24 14:00以降の注文より支払い金額が代引き手数料を含む価格に変更しました。
      </p>
    </Paper>
  );
}
