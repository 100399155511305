import { PropsWithChildren } from "react";

import clsx from "clsx";

import styles from "./SPHistoryTableWrapper.module.scss";

/**
 * TODO:SP用のカードTableコンポーネントを作って汎用化する
 */
export function SPHistoryTableWrapper({ children }: PropsWithChildren) {
  return <div className={clsx(styles.wrapper, "clear__pc")}>{children}</div>;
}
