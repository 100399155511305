import { useEffect, useMemo } from "react";

import { addDays, addHours, format, parse } from "date-fns";
import { ja } from "date-fns/locale";

import { getCommuneSignInUrl } from "@/generated/axios-functions/payseAPI";
import { OrderWithDeliveryDateModel } from "@/models/orderWithDeliveryDate/type";
import { SubscriptionResponseModel, SubscriptionModel } from "@/models/subscription/type";
import { useParsedGetOrderWithDeliveryDate } from "@/queries";
import { getWeekDay } from "@/utils";
import { useIsAppWebView } from "@/utils/hooks";

export type DeliveryDate = {
  deliveryDate: string;
  dateWithWeekDay: string;
  timezoneSuffix?: string;
  suffix: string;
};

const DELIVERY_DELAY = 4;

export const useDeliveryDate = (subscription: SubscriptionResponseModel) => {
  const orders = subscription.subscription?.orders;
  const freezeOrders = subscription.freezeSubscription?.orders;

  const { data: orderWithDeliveryDate, isLoading: isFetchingNormalDeliveryDate } =
    useParsedGetOrderWithDeliveryDate(orders?.length ? orders[orders.length - 1].id : undefined);
  const { data: freezeOrderWithDeliveryDate, isLoading: isFetchingFreezeDeliveryDate } =
    useParsedGetOrderWithDeliveryDate(
      freezeOrders?.length ? freezeOrders[freezeOrders.length - 1].id : undefined
    );
  const normalDeliveryDate = useMemo(
    () => calcNextDeliveryDate(subscription.subscription, orderWithDeliveryDate),
    [subscription.subscription, orderWithDeliveryDate]
  );
  const freezeDeliveryDate = useMemo(
    () => calcNextDeliveryDate(subscription.freezeSubscription, freezeOrderWithDeliveryDate),
    [subscription.freezeSubscription, freezeOrderWithDeliveryDate]
  );
  return {
    orderWithDeliveryDate,
    freezeOrderWithDeliveryDate,
    normalDeliveryDate,
    freezeDeliveryDate,
    isFetchingNormalDeliveryDate,
    isFetchingFreezeDeliveryDate,
  };
};

// 常温と冷凍両方を考慮し次回お届け予定日を算出する
export const calcNextDeliveryDateString = (
  normalDeliveryDate?: DeliveryDate,
  freezeDeliveryDate?: DeliveryDate
): string => {
  if (normalDeliveryDate && freezeDeliveryDate) {
    // 両方サブスクがある場合は直近のお届け日を表示
    if (normalDeliveryDate.deliveryDate > freezeDeliveryDate.deliveryDate) {
      return convertDeliveryDateToString(freezeDeliveryDate);
    } else {
      return convertDeliveryDateToString(normalDeliveryDate);
    }
  }

  // 片方のサブスクがある場合はそのお届け日を表示
  if (normalDeliveryDate) {
    return convertDeliveryDateToString(normalDeliveryDate);
  }

  if (freezeDeliveryDate) {
    return convertDeliveryDateToString(freezeDeliveryDate);
  }

  // サブスクがない場合は何も表示しない
  return "";
};

// 日付を文字列に変換する。
// NOTE: nuxtの実装ではtimezoneSuffixは考慮していない
const convertDeliveryDateToString = (deliveryDate: DeliveryDate) => {
  return `${deliveryDate.dateWithWeekDay}${deliveryDate.suffix}`;
};

// 次回お届け予定日を算出する
export const calcNextDeliveryDate = (
  subscription?: SubscriptionModel,
  orderWithDeliveryDate?: OrderWithDeliveryDateModel
): DeliveryDate | undefined => {
  if (!subscription) return undefined;

  if (orderWithDeliveryDate?.deliveryDate === "") {
    // Note: 初回購入時に最短お届けを選んだ場合(=お届け予定日を指定しない場合)
    if (isRecentOrder(subscription)) {
      // Note: 最新注文日の日付が今日から5日以内のものである場合、注文日から一定時間後の日付を次のお届け予定日とする
      return buildNextDeliveryDateWithDelay(subscription);
    } else {
      // Note: 注文日が5日以上前なら次の定期コースの注文日を返す
      return buildNextDeliveryDate(subscription);
    }
  }

  if (!orderWithDeliveryDate) {
    // NOTE: お届け指定日がない場合、undefinedを返す
    return undefined;
  }
  if (isDeliveryDateOver(orderWithDeliveryDate)) {
    // NOTE: 指定日が過ぎている場合は、次の定期コースの注文日を返す
    return buildNextDeliveryDate(subscription);
  }

  // ユーザーがお届け予定日を指定している場合はお届け予定日をそのまま出す
  return buildNextDeliveryDateWithOrderWithDeliveryDate(orderWithDeliveryDate);
};

const buildNextDeliveryDateWithDelay = (subscription: SubscriptionModel) => {
  // 注文日から一定時間足した日を次のお届け予定日とする
  const nextDate = format(
    addDays(new Date(subscription.lastOrderDate), DELIVERY_DELAY),
    "yyyy/MM/dd"
  );

  return {
    deliveryDate: nextDate,
    dateWithWeekDay: `${nextDate}(${getWeekDay(nextDate)})`,
    suffix: "までにお届け予定",
  };
};

const buildNextDeliveryDate = (subscription: SubscriptionModel) => {
  const nextTimezone =
    subscription.calcNextOrderArrivalTimezoneStr !== "時間指定なし"
      ? subscription.calcNextOrderArrivalTimezoneStr
      : "";

  const nextDate = subscription.calcNextOrderArrivalDate;
  return {
    deliveryDate: nextDate,
    dateWithWeekDay: `${nextDate}(${getWeekDay(nextDate)})`,
    timezoneSuffix: nextTimezone,
    suffix: (subscription.isFastDelivery ? "まで" : "") + "にお届け予定",
  };
};

const buildNextDeliveryDateWithOrderWithDeliveryDate = (
  orderWithDeliveryDate: OrderWithDeliveryDateModel
) => {
  const nextTimezone =
    orderWithDeliveryDate.deliveryTimezone !== "時間指定なし"
      ? orderWithDeliveryDate.deliveryTimezoneStr
      : "";
  const nextDate = orderWithDeliveryDate.deliveryDate;
  return {
    deliveryDate: nextDate,
    dateWithWeekDay: `${nextDate}(${getWeekDay(nextDate)})`,
    timezoneSuffix: nextTimezone,
    suffix: orderWithDeliveryDate.isFastDelivery ? "まで" : "" + "にお届け予定",
  };
};

// 注文日が直近(4日以内)の場合かどうか。
// nuxt: calcIsLatestOrderDateIsLargerThan4daysLaterFromToday
// ※nuxt側だと関数の名前と中身が逆転してしまっている
const isRecentOrder = (subscription: SubscriptionModel) => {
  if (!subscription.orders || subscription.orders?.length === 0) {
    return false;
  }

  const gapHours = 24 * 4 + 9; // 4日以上のズレ+9時間はTimezoneのgapを埋めている
  const lastOrderDateWithGap = addHours(
    new Date(subscription.orders[subscription.orders.length - 1].createdAt || ""),
    gapHours
  );

  return lastOrderDateWithGap > new Date();
};

// お届け予定日が過ぎているかどうか
const isDeliveryDateOver = (orderWithDeliveryDate: OrderWithDeliveryDateModel) => {
  const parsedDeliveryDate = parse(orderWithDeliveryDate.deliveryDate, "yyyy/MM/dd", new Date());
  const nextDate = addHours(parsedDeliveryDate, 24);
  return new Date() > nextDate;
};

// 2023/01/01 -> 01/01
export const extractMonthAndDateString = (date: string) => {
  return format(new Date(date), "MM/dd");
};

// 例：2024/05/23(木) -> 05/23(木)
export const extractMonthAndDateStringWithWeekDay = (dateString: string) => {
  const date = parse(dateString, "yyyy/MM/dd(E)", new Date(), { locale: ja });
  return format(new Date(date), "MM/dd(E)", { locale: ja });
};

// 最新の注文が配達済みかどうか
export const isLastOrderDelivered = (
  subscription: SubscriptionModel,
  orderWithDeliveryDate?: OrderWithDeliveryDateModel
) => {
  if (!orderWithDeliveryDate || orderWithDeliveryDate.deliveryDate === "") {
    return !isRecentOrder(subscription);
  }

  return isDeliveryDateOver(orderWithDeliveryDate);
};

// webViewの場合はbodyにweb viewを示すクラスを追加する。
// useIsAppWebViewではクライアントサイドでしかweb viewを判定していない。
// よってlayoutにはおかずMypageIndexコンポーネント内で実行する
export const useAppWebView = () => {
  const isAppWebView = useIsAppWebView();
  useEffect(() => {
    if (isAppWebView) {
      document.body.classList.add("ios-webview");
    }
  }, [isAppWebView]);
};

export const moveToCommune = async () => {
  const res = await getCommuneSignInUrl();
  if (!res?.url) return;

  window.location.href = res.url;
};
