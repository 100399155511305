import { useCallback, useMemo, useState } from "react";

import { keepPreviousData } from "@tanstack/react-query";
import {
  addDays,
  addHours,
  format,
  isBefore,
  isWithinInterval,
  parse,
  parseISO,
  startOfDay,
} from "date-fns";
import { deepEqual } from "fast-equals";
import { pick } from "ramda";

import { UsePointType } from "@/generated/open-api/schemas";
import { useValidateCoupon } from "@/generated/open-api/subscription/subscription";
import { CartModel } from "@/models/cart/type";
import { DeliveryReceiveOption, DeliveryTimeZone } from "@/models/delivery/consts";
import { PaymentMethod } from "@/models/payment/consts";
import { SubscriptionModel } from "@/models/subscription/type";
import { useParsedSubscriptionCart, useParsedUpdateSubscriptionCoupon } from "@/queries";
import { useParsedUpdateSubscription } from "@/queries/subscription/useParsedUpdateSubscription";
import { toLocalISOString } from "@/utils";
import { ValidationError } from "@/utils/error";
import { useOnce } from "@/utils/hooks";
import { objectMerge } from "@/utils/object";

import { DeliveryScheduleFormSchemaValue } from "./DeliverySchedule/DeliveryScheduleForm/schema";
import { CartProduct } from "./types";

interface GetCanChangeOrderParams {
  subscription: SubscriptionModel | undefined;
  deliveryDate: Date | undefined;
}

/**
 * TODO: orderの変更可能なパターンをコメントに記載する
 */
export const getCanChangeOrder = (params: GetCanChangeOrderParams) => {
  const { subscription, deliveryDate } = params;
  if (!subscription) return true;
  if (subscription.orderIds.length !== 1 || !subscription.isFirstSubscription) {
    // 新規、2回目以降の注文は可能
    return true;
  }
  const now = new Date();
  const firstOrderDate = new Date(subscription.orders[0].createdAt);

  if (!deliveryDate) {
    const gapHours = 24 * 4 + 9;
    // 4日経過している場合は変更可能
    return addHours(firstOrderDate, gapHours) < now;
  }

  // 配達日の24時間後以降であれば変更可能
  const nextDate = addHours(deliveryDate, 24);
  return nextDate < now;
};

type UpdateSubmitParams = {
  subscription: SubscriptionModel;
  deliveryScheduleValues: DeliveryScheduleFormSchemaValue;
};

export const useUpdateSubscriptionSubmit = () => {
  const { mutateAsync: updateSubscription } = useParsedUpdateSubscription();
  return useCallback(
    async (params: UpdateSubmitParams) => {
      const { subscription, deliveryScheduleValues } = params;

      return await updateSubscription({
        data: objectMerge(subscription, {
          nextOrderArrivalDate: format(deliveryScheduleValues.deliveryDate, "yyyy/MM/dd"),
          nextOrderArrivalTimezone: deliveryScheduleValues.deliveryTimezone,
          deliveryLocationCode: deliveryScheduleValues.deliveryReceiveOption,
          isFastDelivery: deliveryScheduleValues.isFastDelivery,
        }),
      });
    },
    [updateSubscription]
  );
};

export const convertSubscriptionToScheduleValues = (
  subscription: SubscriptionModel
): DeliveryScheduleFormSchemaValue => {
  const nextDeliveryDate = parse(subscription.nextOrderArrivalDate, "yyyy/MM/dd", new Date());
  return {
    deliveryDate: nextDeliveryDate,
    deliveryTimezone: subscription.calcNextOrderArrivalTimezone as DeliveryTimeZone,
    deliveryReceiveOption: subscription.deliveryLocationCode as DeliveryReceiveOption,
    isFastDelivery: subscription.isFastDelivery,
  };
};

export const useDeliveryScheduleValues = (subscription: SubscriptionModel | undefined) => {
  /**
   * サーバーのsubscriptionから取得した最新の配達スケジュール
   */
  const serverNextOrderDeliveryStatus = useMemo<Partial<DeliveryScheduleFormSchemaValue>>(() => {
    if (!subscription) return {};
    return convertSubscriptionToScheduleValues(subscription);
  }, [subscription]);

  /**
   * ユーザーの操作によって変更された配達スケジュール
   */
  const [currentNextOrderDeliveryStatus, setCurrentNextOrderDeliveryStatus] = useState(
    serverNextOrderDeliveryStatus
  );

  useOnce(() => {
    setCurrentNextOrderDeliveryStatus(serverNextOrderDeliveryStatus);
  }, !!subscription);

  const isEditedSchedule = useMemo(
    () =>
      !deepEqual(
        {
          ...serverNextOrderDeliveryStatus,
          deliveryDate:
            serverNextOrderDeliveryStatus.deliveryDate &&
            toLocalISOString(serverNextOrderDeliveryStatus.deliveryDate).slice(0, 10),
        },
        {
          ...currentNextOrderDeliveryStatus,
          deliveryDate:
            currentNextOrderDeliveryStatus.deliveryDate &&
            toLocalISOString(currentNextOrderDeliveryStatus.deliveryDate).slice(0, 10),
        }
      ),
    [serverNextOrderDeliveryStatus, currentNextOrderDeliveryStatus]
  );

  const resetToServerNextOrderDeliveryStatus = useCallback(() => {
    setCurrentNextOrderDeliveryStatus(serverNextOrderDeliveryStatus);
  }, [serverNextOrderDeliveryStatus]);

  return {
    /**
     * ユーザの操作によって変更された配達スケジュール
     */
    currentNextOrderDeliveryStatus,
    setCurrentNextOrderDeliveryStatus,
    /**
     * currentNextOrderDeliveryStatusをサーバーから取得した最新の配達スケジュールにリセットする
     */
    resetToServerNextOrderDeliveryStatus,
    isEditedSchedule,
  };
};

type ApplyCouponParams = {
  coupon: string;
  isFrozen: boolean;
  deliveryDate: Date;
  products: { variantId: number; quantity: number }[];
  subscription: SubscriptionModel;
};

export const CouponValidationErrorType = {
  BeforeToday: "BeforeToday",
  BeforeDeliveryDate: "BeforeDeliveryDate",
};

export type CouponValidationErrorType =
  (typeof CouponValidationErrorType)[keyof typeof CouponValidationErrorType];

export class CouponValidationError extends ValidationError {
  type: CouponValidationErrorType;
  constructor({ type, message }: { type: CouponValidationErrorType; message: string }) {
    super(message);
    this.type = type;
  }
}

/**
 * クーポンのバリデーションと適用を1つにまとめたカスタムフック
 * クーポンの期限が
 * - 今日以前								トーストでエラーを表示
 * - 今日以後、配送日以前			画面下部にエラーを表示（入力の変更によって回避できる可能性があるため)
 * - 配送日以後							エラーなし
 * という仕様であるため、今日と配送日の2つの日付でバリデーションを行う。
 *
 * @returns
 */
export const useCouponValidations = () => {
  const { mutateAsync: validateCoupon } = useValidateCoupon();
  const { mutateAsync: updateCoupon } = useParsedUpdateSubscriptionCoupon();
  return useCallback(
    async (params: ApplyCouponParams) => {
      const { coupon, isFrozen, deliveryDate, products, subscription } = params;
      const data = {
        coupon,
        is_freeze: isFrozen,
        products: products.map((product) => ({
          variant_id: String(product.variantId),
          quantity: String(product.quantity),
          subscription: true,
        })),
      };
      // 今日の日付でクーポンのバリデーションを行う
      const resAtToday = await validateCoupon({
        data: {
          ...data,
          delivery_date: format(new Date(), "yyyy-MM-dd"),
        },
      });
      if (resAtToday.result === "ng") {
        throw new CouponValidationError({
          type: "BeforeToday",
          message: resAtToday.message ?? "クーポンコードが正しくありません",
        });
      }

      // 配送日の日付でクーポンのバリデーションを行う
      const resAtDeliveryDate = await validateCoupon({
        data: {
          ...data,
          delivery_date: format(deliveryDate, "yyyy-MM-dd"),
        },
      });
      if (resAtDeliveryDate.result === "ng") {
        throw new CouponValidationError({
          type: "BeforeDeliveryDate",
          message:
            "適用中のクーポンの条件外となります。お届け予定日・注文内容の変更、もしくはクーポンの削除をお願いいたします。",
        });
      }
      await updateCoupon({ data: { ...subscription, coupon } });
    },
    [updateCoupon, validateCoupon]
  );
};

export interface UseSubscriptionCartValuesParam {
  products: CartProduct[];
  deliveryDate?: Date;
  paymentMethod?: PaymentMethod;
  nextUsePoint?: number;
  nextUsePointType: UsePointType;
  coupon: string;
}

/**
 * カート情報の取得と初期値の管理を1つにまとめたカスタムフック
 * @returns
 */
export const useSubscriptionCartValues = (params: UseSubscriptionCartValuesParam) => {
  // 選択した商品、クーポン、ポイントが変わったらカート情報を再取得する
  const { data: currentSubscriptionCart, isFetching: isFetchingSubscriptionCart } =
    useParsedSubscriptionCart(
      {
        products: params.products.map((product) => ({
          variantId: String(product.variantId),
          quantity: String(product.quantity),
          subscription: true,
        })),
        deliveryDate: params.deliveryDate ? format(params.deliveryDate, "yyyy/MM/dd") : undefined,
        usePoint: params.nextUsePoint ?? 0,
        usePointType: params.nextUsePointType,
        coupon: params.coupon || undefined,
        paymentMethod: params.paymentMethod,
      },
      { placeholderData: keepPreviousData }
    );

  // サーバーから取得した最新のカート情報
  const [serverSubscriptionCart, setServerSubscriptionCart] = useState(currentSubscriptionCart);
  useOnce(() => {
    // 初回目のレンダリング時にサーバーから取得したカート情報を保持する
    setServerSubscriptionCart(currentSubscriptionCart);
  }, !!currentSubscriptionCart);

  const isEditedSubscriptionCart = useMemo(() => {
    if (!currentSubscriptionCart || !serverSubscriptionCart) return false;
    // このhooksで管理している値のみ比較
    return !deepEqual(
      pick(["usePointType", "coupon", "products"], currentSubscriptionCart),
      pick(["usePointType", "coupon", "products"], serverSubscriptionCart)
    );
  }, [currentSubscriptionCart, serverSubscriptionCart]);

  // 最新の値をstateに反映する
  const flushServerSubscriptionCart = useCallback(() => {
    setServerSubscriptionCart(currentSubscriptionCart);
  }, [currentSubscriptionCart]);

  return {
    /**
     * ユーザの操作によって最新のカート情報
     */
    currentSubscriptionCart,
    /**
     * 今のサブスクライブから取得した最新のカート情報
     */
    serverSubscriptionCart,
    /**
     * ユーザの操作によってカート情報が変更されているか
     */
    isEditedSubscriptionCart,
    /**
     * ユーザの操作によって変更されたカート情報をサーバーから取得した最新のカート情報に反映する
     */
    flushServerSubscriptionCart,
    /**
     * カート情報を取得中かどうか
     */
    isFetchingSubscriptionCart,
  };
};

const ignoreVariantIds = [
  33022154375252, // BASE BREAD リッチ
];

export function getBrandNewDiscountMessages(
  subscriptionCart: CartModel,
  deliveryDate: Date
): string[] {
  const discounts = subscriptionCart.productBrandNewDiscounts;

  // カートがFIRST CLASSボーナスの対象でない場合、空の配列を返す
  if (!isTargetOfFirstClassBonus(subscriptionCart)) return [];
  // カートが有効な新商品の配送日であれば、メッセージは不要なので空の配列を返す
  if (subscriptionCart.isValidNewProductDeliveryDate) return [];
  // 割引情報が存在しない、もしくは割引がない場合、メッセージを返さない
  if (!discounts || discounts.length === 0) return [];

  const messages: string[] = [];

  for (const product of subscriptionCart.products) {
    // 無視する商品を除外
    if (ignoreVariantIds.includes(product.variantId)) continue;

    const productBrandNewDiscount = discounts.find(
      (productDiscount) => productDiscount.variantId === product.variantId
    );

    // 該当する割引がなければ次の商品へ
    if (!productBrandNewDiscount) continue;

    const discountEndDate = parseISO(productBrandNewDiscount.endDate.formatted);

    // 今日の日付を取得
    const todayDate = new Date();

    // 割引終了日が今日よりも前であればメッセージを表示しない
    if (isBefore(startOfDay(discountEndDate), startOfDay(todayDate))) {
      continue;
    }

    // 対象期間は前後4日間
    const offsetDays = 4;
    const startDate = addDays(parseISO(productBrandNewDiscount.startDate.formatted), offsetDays);
    const endDate = addDays(parseISO(productBrandNewDiscount.endDate.formatted), offsetDays);

    // 配送日が割引対象期間内でない場合、メッセージを追加
    if (deliveryDate && !isWithinInterval(deliveryDate, { start: startDate, end: endDate })) {
      messages.push(
        `「${product.title}${product.variantTitle}」30%オフ対象期間は、${format(endDate, "Y年M月d日")}お届け分までです。`
      );
    }
  }

  return messages;
}

/**
 * ランクがFIRSTクラス以上かどうかを判定する
 * 旧ランクにも対応
 * @param subscriptionCart
 * @returns
 */
function isTargetOfFirstClassBonus(subscriptionCart: CartModel) {
  const { rankName, rankNameOld } = subscriptionCart;
  return (
    rankName === "FIRST" ||
    rankName === "VIP" ||
    rankNameOld === "GOLD" ||
    rankNameOld === "DIAMOND"
  );
}
