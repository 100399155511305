import { useCallback } from "react";

import clsx from "clsx";

import styles from "./SurveyRadioButtonGroup.module.scss";

interface SurveyRadioButtonOption {
  value: string;
  label: string;
}

type AnswerType = "answer" | "otherAnswer";

export interface SurveyRadioButtonGroupProps {
  questionId: string;
  question: string;
  options: SurveyRadioButtonOption[];
  hasOtherSelection?: boolean;
  answer?: string;
  otherAnswer?: string;
  onChange?: (questionId: string, type: AnswerType, value: string[] | string) => void;
}

export function SurveyRadioButtonGroup({
  questionId,
  question,
  options,
  hasOtherSelection,
  answer,
  otherAnswer,
  onChange,
}: SurveyRadioButtonGroupProps): React.ReactNode {
  const innerHandleChange = useCallback(
    (type: AnswerType) => (event: React.ChangeEvent<HTMLInputElement>) => {
      // 選択肢の場合配列へ変換
      onChange?.(questionId, type, type === "answer" ? [event.target.value] : event.target.value);
    },
    [onChange, questionId]
  );

  const isLastAnswerSelected = options[options.length - 1].value === answer;
  const displayOtherInput = hasOtherSelection && isLastAnswerSelected;

  return (
    <>
      <p className="text__m text__bold mg__bottom__m">{question}</p>
      <div className={clsx("gray__table mg__bottom__m", styles.lastChildBorder)}>
        {options.map((option) => {
          const inputId = `${questionId}${option.label}`;
          return (
            <dl key={option.value} className="toggle__dl border__bottom">
              <dt>
                <input
                  id={inputId}
                  name={questionId}
                  value={option.value}
                  className="radio__input"
                  type="radio"
                  required
                  onChange={innerHandleChange("answer")}
                  checked={answer === option.value}
                />
                <label htmlFor={inputId} className={clsx("text__m", styles.fullWidth)}>
                  {option.label}
                </label>
              </dt>
            </dl>
          );
        })}
      </div>
      {displayOtherInput && (
        <>
          <p className="text__m mg__bottom__m">「その他」を選択した場合、内容を教えてください。</p>
          <div className="gray__table mg__bottom__l">
            <dl className="text__m table">
              <dd className="input">
                <input
                  id={questionId}
                  type="text"
                  maxLength={250}
                  className="text__m"
                  placeholder="その他を選んだ方はこちらに"
                  value={otherAnswer ?? ""}
                  onChange={innerHandleChange("otherAnswer")}
                />
              </dd>
            </dl>
          </div>
        </>
      )}
    </>
  );
}
