"use client";

import { useCallback, useState } from "react";

import { Column, Row } from "@/components/containers";
import { Hr } from "@/components/displays";
import { htmlToast } from "@/components/feedbacks";
import { useDeleteMileGiftsTrades } from "@/generated/open-api/mile/mile";
import { MileGiftsTradesItemModel } from "@/models/mile/type";
import { useParsedGetMileGiftsTrades, useParsedGetMileSummary } from "@/queries";
import { getErrorMessages } from "@/utils/error";

import { MileGiftsTradeItem } from "./MileGiftsTradeItem";
import styles from "./MileGiftsTrades.module.scss";
import { MileGiftTradeModal } from "../MileGiftTradeModal";

export function MileGiftsTrades(): React.ReactNode {
  const { data: mileGiftsTrades, refetch: refetchMileGiftsTrades } = useParsedGetMileGiftsTrades();
  const { data: mileSummary, refetch: refetchMileSummary } = useParsedGetMileSummary();
  const { mutateAsync: cancelMileGiftsTrade, isPending } = useDeleteMileGiftsTrades();
  const availableMiles = mileSummary?.availableMiles ?? 0;
  const onlyCanceled = mileGiftsTrades?.every(
    (mileGiftsTrade) => mileGiftsTrade.deliveryStatus === "Cancel"
  );
  const [isAbortModalOpen, setIsAbortModalOpen] = useState(false);
  const [selectedMileGiftsTrade, setSelectedMileGiftsTrade] = useState<MileGiftsTradesItemModel>();

  const toggleAbortModal = useCallback(() => {
    setIsAbortModalOpen((prev) => !prev);
  }, []);

  const handleOpenAbortModal = useCallback(
    (id: number) => {
      const selectedGiftsTrade = mileGiftsTrades?.find(
        (mileGiftsTrade) => mileGiftsTrade.id === id
      );
      if (!selectedGiftsTrade) {
        return;
      }

      setSelectedMileGiftsTrade(selectedGiftsTrade);
      toggleAbortModal();
    },
    [mileGiftsTrades, toggleAbortModal]
  );

  const handleCancel = useCallback(async () => {
    try {
      if (selectedMileGiftsTrade?.id) {
        await cancelMileGiftsTrade({ params: { id: selectedMileGiftsTrade.id } });
        await refetchMileGiftsTrades();
        await refetchMileSummary();
      }
      toggleAbortModal();
    } catch (error) {
      htmlToast.error(getErrorMessages(error));
    }
  }, [
    cancelMileGiftsTrade,
    refetchMileGiftsTrades,
    refetchMileSummary,
    selectedMileGiftsTrade?.id,
    toggleAbortModal,
  ]);

  // データがない場合は何も表示しない
  if (!mileGiftsTrades || mileGiftsTrades.length === 0) {
    return null;
  }

  return (
    <section className={styles.giftsTradesSection}>
      <div className={styles.title}>お届け予定のギフト</div>
      <div className={styles.container}>
        <Column gap={20}>
          <Row gap={20} flex="wrap">
            {mileGiftsTrades?.map((mileGiftsTrade) => (
              <Column gap={20} key={mileGiftsTrade.id} className={styles.giftsTradeItem}>
                <MileGiftsTradeItem
                  onCancel={handleOpenAbortModal}
                  id={mileGiftsTrade.id}
                  deliveryDate={mileGiftsTrade.deliveryDate}
                  deliveryStatus={mileGiftsTrade.deliveryStatus}
                  imageUrl={mileGiftsTrade.imageUrl}
                  name={mileGiftsTrade.name}
                  nextOrderChangeDeadlineDate={mileGiftsTrade.nextOrderChangeDeadlineDate}
                />
                <Hr noMargin />
              </Column>
            ))}
          </Row>
          {!onlyCanceled && (
            <div className={styles.annotation}>※次回の継続コースに同梱してお届けします。</div>
          )}
        </Column>
        <MileGiftTradeModal
          type="abort"
          open={isAbortModalOpen}
          giftTitle={selectedMileGiftsTrade?.name || ""}
          giftDescription=""
          giftImageUrl={selectedMileGiftsTrade?.imageUrl || ""}
          beforePrice={availableMiles}
          afterPrice={availableMiles + (selectedMileGiftsTrade?.useMile || 0)}
          onNegative={toggleAbortModal}
          onPositive={handleCancel}
          isOperationPending={isPending}
        />
      </div>
    </section>
  );
}
