"use client";
import { useCallback, useMemo, useState } from "react";

import clsx from "clsx";

import { Column, Row } from "@/components/containers";
import { Image } from "@/components/displays";
import { Chip } from "@/components/displays";
import { Button } from "@/components/inputs";
import { CartModel } from "@/models/cart/type";
import { NutrientModel } from "@/models/nutrient/type";
import { ProductModel } from "@/models/product/type";
import { SubscriptionModel } from "@/models/subscription/type";
import { removeEmpty } from "@/utils/array";
import { objectMerge } from "@/utils/object";

import { AdditionalProductsSections } from "./AdditionalProductsSections";
import {
  Category,
  SubscriptionProduct,
  convertSubscriptionProduct,
  getProductCategory,
} from "./helper";
import styles from "./NextDelivery.module.scss";
import { NutrientCard } from "./NutrientCard";
import { ProductTotalCounts } from "./ProductTotalCounts";
import { SubscriptionProductItem } from "./SubscriptionProductItem";
import { CartProduct, Temperature } from "../types";

interface NextDeliveryProps {
  subscription?: SubscriptionModel;
  nutrients: NutrientModel[];
  temperature: Temperature;
  cart: CartModel | undefined;
  defaultCart: CartModel | undefined;
  allProducts: ProductModel[];
  comebackProducts: ProductModel[];
  deliveryDate: Date | null;
  onChangeQuantity?: (products: CartProduct[]) => void; // undefinedだと変更不可
}

export function NextDelivery({
  subscription,
  nutrients,
  temperature,
  cart,
  defaultCart,
  allProducts,
  comebackProducts,
  deliveryDate,
  onChangeQuantity,
}: NextDeliveryProps): React.ReactNode {
  // 保存されているsubscription情報から取得したカートを使って、定期購入中の商品IDを取得
  const originalSelectedVariantIds = useMemo(
    () => defaultCart?.products.map((product) => product.variantId) ?? [],
    [defaultCart]
  );
  const [showMoreNutrients, setShowMoreNutrients] = useState(false);
  const [firstNutrient, ...otherNutrients] = nutrients;

  /**
   * サーバーに保存されているサブスクライブ中の商品情報。下記の２種類のデータがあります
   * - 数量はサーバーに保存されている情報と一致している商品
   * - 数量がユーザーの操作によって変わった商品
   */
  const savedSubscriptionProducts = useMemo(
    () =>
      !cart
        ? []
        : removeEmpty(
            originalSelectedVariantIds.map((variantId) => {
              const baseProduct = allProducts.find((p) => p.variantId === variantId);
              // ユーザが変更した未保存のカート情報
              const product = cart?.products.find((p) => p.variantId === variantId);
              // 保存されているsubscriptionから取得したカート情報
              const defaultProduct = defaultCart?.products.find((p) => p.variantId === variantId);
              const quantity = product?.quantity ?? 0;
              return baseProduct
                ? {
                    ...convertSubscriptionProduct({
                      // cart.productsにはname属性がundefinedであるため、allProductsから取得したproductをマージする
                      product: objectMerge(baseProduct, product ?? {}),
                      defaultProduct,
                      brandNewDiscounts: cart.productBrandNewDiscounts,
                    }),
                    quantity,
                  }
                : null;
            })
          ),
    [allProducts, cart, defaultCart?.products, originalSelectedVariantIds]
  );

  /**
   * サブスクライブ中の商品以外の商品情報
   * - ユーザーが追加した商品
   * - 選択されていない商品
   */
  const otherSubscriptionProducts = useMemo<SubscriptionProduct[]>(() => {
    return removeEmpty(
      allProducts.map((product) => {
        if (originalSelectedVariantIds.includes(product.variantId)) return null;
        // ユーザが追加した未保存のカート情報
        const addedProduct = cart?.products.find((p) => p.variantId === product.variantId);
        const quantity = addedProduct?.quantity ?? 0;
        return {
          ...convertSubscriptionProduct({
            product: objectMerge(product, addedProduct ?? {}),
            defaultProduct: product,
            brandNewDiscounts: cart?.productBrandNewDiscounts,
          }),
          quantity,
        };
      })
    );
  }, [allProducts, cart?.productBrandNewDiscounts, cart?.products, originalSelectedVariantIds]);

  const [openedCategories, setOpenedCategories] = useState<Record<Category, boolean>>({
    bread: false,
    pancake: false,
    pasta: false,
    cookie: false,
    deliPasta: false,
  });

  const [showComeback, setShowComeback] = useState(() => {
    return comebackProducts.length > 0 && !subscription;
  });

  const handleComeback = useCallback(() => {
    if (!onChangeQuantity) return;
    // ボタンの操作
    setShowComeback(false);

    // アコーディオンの操作
    const comebackProductWithName = removeEmpty(
      comebackProducts.map((product) => {
        const baseProduct = allProducts.find((p) => p.variantId === product.variantId);
        // comebackProductsはname属性がundefinedであるため、allProductsから取得したproductをマージする
        return baseProduct ? { ...product, name: baseProduct.name } : null;
      })
    );
    const categories = Array.from(
      new Set(comebackProductWithName.map((product) => getProductCategory(product)))
    );
    setOpenedCategories((prev) =>
      categories.reduce((acc, category) => ({ ...acc, [category]: true }), prev)
    );

    // 商品の追加
    onChangeQuantity(
      comebackProducts.map((product) => ({
        variantId: product.variantId,
        quantity: product.quantity,
      }))
    );
  }, [allProducts, comebackProducts, onChangeQuantity]);

  return (
    <Column className={styles[`${temperature}Container`]}>
      <Row>
        <Chip variant="outlined" color="red">
          {defaultCart?.isFirstSubscriptionOrder
            ? "継続コース割引率：20%（初回のみ）"
            : subscription?.isPastDiscount
              ? "継続コース割引率：20%"
              : "継続コース割引率：10%"}
        </Chip>
      </Row>
      {showComeback && onChangeQuantity && (
        <Row>
          <Button variants="gray" rounded onClick={handleComeback}>
            前回の注文内容をまとめて追加する
          </Button>
        </Row>
      )}
      <Column className={styles.body}>
        {(cart?.products.length ?? 0) > 0 && (
          <>
            {subscription && (
              <Row className={styles.amount}>
                <ProductTotalCounts
                  temperature={temperature}
                  products={savedSubscriptionProducts}
                />
              </Row>
            )}
            {nutrients.length > 0 && (
              <Column className={styles.nextNutrient}>
                <Row className={styles.nutrientHeader}>
                  <Image
                    src="https://asset.basefood.co.jp/images/parts/txt_nutrients.png"
                    alt="食材に置き換えると"
                    size={{ width: 279, height: 44 }}
                  />
                </Row>
                <Column className={styles.nutrientItems}>
                  {firstNutrient && <NutrientCard nutrient={firstNutrient} />}
                  {!showMoreNutrients ? (
                    <Row className={styles.showMoreNutrients}>
                      <span
                        onClick={() => setShowMoreNutrients(true)}
                        className={clsx("text__bold", "text__s")}
                      >
                        さらに表示
                      </span>
                    </Row>
                  ) : (
                    <>
                      {otherNutrients.map((nutrient) => (
                        <NutrientCard key={nutrient.nutrient} nutrient={nutrient} />
                      ))}
                      <p className={clsx("text__s", "text__bold", "text__center")}>
                        その他28種類の栄養素が入っています。
                      </p>
                      <p
                        className={clsx(
                          "text__s",
                          "text__gray__dark",
                          "mg__top__s",
                          "mg__bottom__s"
                        )}
                      >
                        累計食数を「BASE BREAD
                        チョコレート」1袋分の各栄養成分値を元に算出し、食材例で換算したものです。各食材の栄養成分は八訂食品栄養成分表を参照し、各食材重量は「食品の栄養とカロリー事典　第3版
                        | 女子栄養大学出版部 」を参照しています。
                      </p>
                      <Row className={styles.showMoreNutrients}>
                        <span
                          onClick={() => setShowMoreNutrients(false)}
                          className={clsx("text__bold", "text__s")}
                        >
                          閉じる
                        </span>
                      </Row>
                    </>
                  )}
                </Column>
              </Column>
            )}
            <Column className={styles.subscriptionProducts}>
              {savedSubscriptionProducts.map((product) => (
                <SubscriptionProductItem
                  key={product.variantId}
                  product={product}
                  deliveryDate={deliveryDate}
                  isFirstSubscriptionOrder={defaultCart?.isFirstSubscriptionOrder ?? false}
                  pastDiscountRate={defaultCart?.pastDiscountRate ?? false}
                  onChangeQuantity={
                    onChangeQuantity
                      ? (quantity) => onChangeQuantity([{ variantId: product.variantId, quantity }])
                      : undefined
                  }
                />
              ))}
            </Column>
          </>
        )}

        <Column className={styles.additionalProducts}>
          <AdditionalProductsSections
            products={otherSubscriptionProducts}
            onChangeQuantity={onChangeQuantity}
            temperature={temperature}
            deliveryDate={deliveryDate}
            isFirstSubscriptionOrder={defaultCart?.isFirstSubscriptionOrder ?? false}
            pastDiscountRate={defaultCart?.pastDiscountRate ?? false}
            openedCategories={openedCategories}
            onOpenChanged={(category, open) =>
              setOpenedCategories((prev) => ({ ...prev, [category]: open }))
            }
          />
        </Column>
      </Column>
    </Column>
  );
}
