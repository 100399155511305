import { useCallback, useMemo } from "react";

import clsx from "clsx";

import styles from "./SurveyCheckboxes.module.scss";

interface SurveyCheckboxesOption {
  value: string;
  label: string;
}

type AnswerType = "answer" | "otherAnswer";

export interface SurveyCheckboxesProps {
  questionId: string;
  question: string;
  options: SurveyCheckboxesOption[];
  hasOtherSelection?: boolean;
  answers?: string[];
  otherAnswer?: string;
  onChange?: (questionId: string, type: AnswerType, value: string[] | string) => void;
}

const ExcludeAllOthersAnswerString = "99";

export function SurveyCheckboxes({
  questionId,
  question,
  options,
  hasOtherSelection,
  answers,
  otherAnswer,
  onChange,
}: SurveyCheckboxesProps): React.ReactNode {
  const innerHandleChange = useCallback(
    (type: AnswerType) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (type === "answer") {
        let newAnswers = [];
        // 選択された選択肢を更新する
        if (!event.target.checked) {
          // 選択が外された場合
          newAnswers = (answers ?? [])?.filter((answer) => answer !== event.target.value);
        } else {
          // 選択された場合
          if (event.target.value === ExcludeAllOthersAnswerString) {
            // "99"排他的な選択肢が選択された場合、他の選択肢を全て外す
            newAnswers = [ExcludeAllOthersAnswerString];
          } else {
            // それ以外の場合、"99"を外して選択された選択肢を追加する
            newAnswers = (answers ?? [])
              .filter((answer) => answer !== ExcludeAllOthersAnswerString)
              .concat(event.target.value);
          }
        }
        onChange?.(questionId, type, newAnswers);
      } else {
        onChange?.(questionId, type, event.target.value);
      }
    },
    [answers, onChange, questionId]
  );

  const isLastAnswerSelected = useMemo(() => {
    // ExcludeAllOthersAnswerString"99"は排他的な選択肢であり、「その他」の後ろに配置されている
    const optionsWithoutExcludeAllOthers = options.filter(
      (option) => option.value !== ExcludeAllOthersAnswerString
    );
    const otherAnswerValue =
      optionsWithoutExcludeAllOthers[optionsWithoutExcludeAllOthers.length - 1].value;

    // その他が選択されているかどうか
    return answers?.includes(otherAnswerValue);
  }, [answers, options]);
  const displayOtherInput = hasOtherSelection && isLastAnswerSelected;

  return (
    <>
      <p className="text__m text__bold mg__bottom__m">{question} （複数選択可）</p>
      <div className={clsx("gray__table mg__bottom__m", styles.lastChildBorder)}>
        {options.map((option) => {
          const inputId = `${questionId}${option.label}`;
          return (
            <dl key={option.value} className="toggle__dl border__bottom">
              <dt>
                <input
                  id={inputId}
                  name={questionId}
                  value={option.value}
                  className="checkbox__input"
                  type="checkbox"
                  required
                  onChange={innerHandleChange("answer")}
                  checked={answers?.includes(option.value)}
                />
                <label htmlFor={inputId} className={clsx("text__m", styles.fullWidth)}>
                  {option.label}
                </label>
              </dt>
            </dl>
          );
        })}
      </div>
      {displayOtherInput && (
        <>
          <p className="text__m mg__bottom__m">「その他」を選択した場合、内容を教えてください。</p>
          <div className="gray__table mg__bottom__l">
            <dl className="text__m table">
              <dd className="input">
                <input
                  id={questionId}
                  type="text"
                  maxLength={250}
                  className="text__m"
                  placeholder="その他を選んだ方はこちらに"
                  value={otherAnswer ?? ""}
                  onChange={innerHandleChange("otherAnswer")}
                />
              </dd>
            </dl>
          </div>
        </>
      )}
    </>
  );
}
