import { SaveSurveyBody } from "@/generated/open-api/schemas";

import { SurveyAnswers } from "./SurveyForm";

export function covertToApiSurveyAnswers(answers?: SurveyAnswers): SaveSurveyBody["answers"] {
  if (!answers) {
    return [];
  }

  const apiSurveyAnswers = Object.entries(answers).map(([questionId, answer]) => {
    return {
      surveyId: questionId,
      answer: answer.answer ?? [],
      otherAnswer: answer.otherAnswer,
    };
  });
  return apiSurveyAnswers;
}
