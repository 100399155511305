"use client";
import { useCallback, useMemo, useState } from "react";

import clsx from "clsx";
import { addDays, format } from "date-fns";
import { ja } from "date-fns/locale";

import { Column, Row } from "@/components/containers";
import { Chip } from "@/components/displays";
import { Modal } from "@/components/feedbacks";
import { Button, RadioButtonGroup, Selector } from "@/components/inputs";
import { useSaveSurvey } from "@/generated/open-api/survey/survey";
import { CustomerModel } from "@/models/customer/type";
import { SubscriptionModel } from "@/models/subscription/type";
import { SurveyModel } from "@/models/survey/type";
import { useParsedUpdateSubscriptionDeliveryDate } from "@/queries";
import { useParsedGetSkipSurvey } from "@/queries/survey/useParsedGetSkipSurvey";
import { pushGtmEvent } from "@/utils/gtm";
import { useDisableBodyScroll } from "@/utils/hooks";

import { RetentionContents } from "./RetentionContents";
import styles from "./SkipModal.module.scss";
import { SkipModalEvent } from "./types";
import { Temperature } from "../types";

interface SkipModalProps {
  open: boolean;
  temperature: Temperature;
  onAction: (event: SkipModalEvent) => void;
  originalDeliveryDate?: Date;
  subscription: SubscriptionModel;
  customer: CustomerModel;
}

const baseSkipOptions = [
  { value: 1, label: "1週間" },
  { value: 2, label: "2週間" },
  { value: 4, label: "4週間" },
] as const;

export function SkipModal({
  open,
  temperature,
  onAction,
  originalDeliveryDate,
  subscription,
  customer,
}: SkipModalProps): React.ReactNode {
  useDisableBodyScroll(open);

  const { data: surveyData } = useParsedGetSkipSurvey(temperature);
  const survey = surveyData.surveyList[0] as SurveyModel | undefined;
  const reasonOptions = useMemo(() => {
    const placeHolder = { value: "0", label: survey?.questionText ?? "" };
    if (!survey) return [placeHolder];
    return [placeHolder, ...survey.answerOptions];
  }, [survey]);

  const skipOptions = useMemo(
    () =>
      baseSkipOptions.map((option) => ({
        value: option.value,
        label: <span className="text__bold wsnr">{option.label}</span>,
        disabled: !subscription.skipInfo[`canBackward${option.value}Week`],
      })),
    [subscription.skipInfo]
  );

  const [skipInterval, setSkipInterval] = useState(skipOptions[0].value);
  const [skipReason, setSkipReason] = useState(reasonOptions[0].value);
  const afterDeliveryDate = useMemo(() => {
    if (!originalDeliveryDate) return null;
    const weekdays = 7;
    return addDays(originalDeliveryDate, skipInterval * weekdays);
  }, [originalDeliveryDate, skipInterval]);

  const [otherReason, setOtherReason] = useState("");

  const { mutateAsync: updateDeliveryDate } = useParsedUpdateSubscriptionDeliveryDate();
  const { mutateAsync: saveSurvey } = useSaveSurvey();
  const handleSkip = useCallback(async () => {
    if (!survey || !afterDeliveryDate) return;
    const surveyPromise = saveSurvey({
      data: {
        email: customer.email,
        answers: [{ answer: [skipReason], otherAnswer: otherReason, surveyId: survey.id }],
      },
    });
    const deliveryDatePromise = updateDeliveryDate({
      data: {
        ...subscription,
        nextOrderArrivalDate: format(afterDeliveryDate, "yyyy-MM-dd"),
      },
    });
    await Promise.all([surveyPromise, deliveryDatePromise]);
    onAction({ type: "skip", otherReason, reason: skipReason });
    pushGtmEvent({ event: `skip_${skipInterval / 7}` });
  }, [
    afterDeliveryDate,
    customer.email,
    onAction,
    otherReason,
    saveSurvey,
    skipInterval,
    skipReason,
    subscription,
    survey,
    updateDeliveryDate,
  ]);

  return (
    <Modal
      open={open}
      className={styles.root}
      closeModal={() => onAction({ type: "cancel" })}
      displayCloseButton={false}
      header={
        <Row className={styles.header}>
          <p className="text__l text__bold">次回のお届けをスキップする</p>
          <div className={styles.closeIcon} onClick={() => onAction({ type: "cancel" })} />
        </Row>
      }
      footer={
        <Row className={styles.modalFooter}>
          <Button variants="gray" onClick={() => onAction({ type: "cancel" })}>
            キャンセル
          </Button>
          <Button variants="yellow" onClick={handleSkip}>
            確定
          </Button>
        </Row>
      }
    >
      <Column className={styles.body}>
        <Row align="center">
          <Chip color="gray" className={styles.chip}>
            変更前
          </Chip>
          <p>
            {originalDeliveryDate
              ? format(originalDeliveryDate, "yyyy/MM/dd(E)", { locale: ja })
              : "-"}
          </p>
        </Row>
        <span className={styles.arrowDown} />
        <Row>
          <RadioButtonGroup
            value={skipInterval}
            onChange={setSkipInterval}
            name="skip-interval"
            direction="row"
            outlined
            className={styles.radioButtons}
            options={skipOptions}
          />
        </Row>
        <Row>
          <Chip color="yellow" className={styles.chip}>
            変更後
          </Chip>
          <p className={clsx("text__l", "text__bold")}>
            {afterDeliveryDate ? format(afterDeliveryDate, "yyyy/MM/dd(E)", { locale: ja }) : "-"}
          </p>
        </Row>
        <Row className={styles.reasonSelectContainer}>
          <Selector
            className={styles.reasonSelect}
            options={reasonOptions}
            onChange={setSkipReason}
          />
        </Row>
        <Row className={styles.retentionContent}>
          <RetentionContents
            answerIndex={skipReason}
            onAction={onAction}
            onChangeOtherReason={setOtherReason}
          />
        </Row>
      </Column>
    </Modal>
  );
}
