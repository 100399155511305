"use client";
import { useCallback, useState } from "react";

import { LoadingOverlay, useAuth } from "@/components";

export function LogoutButton(): React.ReactNode {
  const { logout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const handleLogout = useCallback(async () => {
    try {
      setIsLoading(true);
      await logout();
    } finally {
      setIsLoading(false);
    }
  }, [logout]);

  return (
    <LoadingOverlay isLoading={isLoading}>
      <div className="row">
        <div className="col-12 text__center">
          <button type="button" className="btn inline round border__bk" onClick={handleLogout}>
            ログアウト
          </button>
        </div>
      </div>
    </LoadingOverlay>
  );
}
