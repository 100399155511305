import { CartItemModel } from "@/models/cart/type";
import { NuxtCommonCartItem } from "@/storage/types";

export const convertToNuxtStoreCartItems = (cartItems: CartItemModel[]): NuxtCommonCartItem[] => {
  return cartItems.map((product) => ({
    variant_id: product.variantId.toString(),
    subscription: product.subscription,
    quantity: product.quantity,
  }));
};

// 決済用ページへ飛ぶためのURLを生成する
export const buildCheckoutUrl = (
  cartItems: CartItemModel[],
  coupon = null,
  setNames: string[] = []
) => {
  const url = new URL(`${window.location.origin}/checkout`);
  const nuxtItems = convertToNuxtStoreCartItems(cartItems);
  url.searchParams.append("cart[products]", JSON.stringify(nuxtItems));
  url.searchParams.append("cart[set_names]", JSON.stringify(setNames));
  if (coupon) {
    url.searchParams.append("cart[coupon]", coupon);
  }

  return url.toString();
};
