"use client";

import { LinkButton } from "@/components/buttons";
import { Column, Row } from "@/components/containers";
import { LoadingOverlay, Paper } from "@/components/displays";
import { MileIcon } from "@/components/icons";
import { useParsedGetMileSummary } from "@/queries";
import { numberFormat } from "@/utils";

import styles from "./RankMileStatusMileSummary.module.scss";

export function RankMileStatusMileSummary(): React.ReactNode {
  const { data: mileSummary, isLoading: isGettingMileSummary } = useParsedGetMileSummary();

  return (
    <Paper padding="fixed20">
      <LoadingOverlay isLoading={isGettingMileSummary}>
        <Column gap={20} align="center">
          <div className="text__l text__bold">現在の保有マイル</div>
          <Row align="center" gap={10}>
            <MileIcon />
            <div className={styles.availableMile}>{numberFormat(mileSummary?.availableMiles)}</div>
          </Row>
          <LinkButton href="/mypage/mile/exchange" noMargin size="mini">
            ギフトに交換
          </LinkButton>
        </Column>
      </LoadingOverlay>
    </Paper>
  );
}
