import { useMemo } from "react";

import clsx from "clsx";

import { SubscriptionResponseModel } from "@/models/subscription/type";
import { useParsedGetCustomer } from "@/queries";

import styles from "./DeliveryStatus.module.scss";
import { calcNextDeliveryDateString, DeliveryDate } from "../helpers";

interface DeliveryStatusProps {
  subscription: SubscriptionResponseModel;
  normalDeliveryDate?: DeliveryDate;
  freezeDeliveryDate?: DeliveryDate;
}

export function DeliveryStatus({
  subscription,
  normalDeliveryDate,
  freezeDeliveryDate,
}: DeliveryStatusProps): React.ReactNode {
  const { data: customer } = useParsedGetCustomer();

  const showNextDeliveryDate = useMemo(() => {
    return subscription.subscription || subscription.freezeSubscription;
  }, [subscription]);

  const nextDeliveryDate = useMemo(
    () => calcNextDeliveryDateString(normalDeliveryDate, freezeDeliveryDate),
    [normalDeliveryDate, freezeDeliveryDate]
  );

  return (
    <div className={clsx("bg__yellow", styles.customer)}>
      <div className="text__s">
        {customer.lastName} {customer.firstName} さま
      </div>
      {showNextDeliveryDate && (
        <div className="text__right text__s text__bold">
          <span className={styles.underline}>{nextDeliveryDate}</span>
          {!subscription.subscription && !subscription.freezeSubscription && (
            <button className={clsx("text__s text__bold", styles.button)}>
              <span className="clear__sp">継続コースを開始する</span>
              <span className="clear__sp">マイページで継続コースを開始</span>
            </button>
          )}
        </div>
      )}
    </div>
  );
}
