"use client";

import { Fragment, useCallback, useState } from "react";

import { SurveyType } from "@/models/survey/consts";
import { SurveyModel } from "@/models/survey/type";

import { SurveyCheckboxes } from "./SurveyCheckboxes";
import { SurveyRadioButtonGroup } from "./SurveyRadioButtonGroup";

export interface SurveyAnswers
  extends Record<string, { answer?: string[]; otherAnswer?: string }> {}

export interface SurveyFormProps {
  questions: SurveyModel[];
  onChange?: (answers: SurveyAnswers) => void;
}

export function SurveyForm({ questions, onChange }: SurveyFormProps): React.ReactNode {
  const [answers, setAnswers] = useState<SurveyAnswers>({});

  const handleChange = useCallback(
    (questionId: string, type: "answer" | "otherAnswer", value: string | string[]) => {
      setAnswers((prev) => {
        const newValue = {
          ...prev[questionId],
          [type]: value,
        };

        const newAnswers = {
          ...prev,
          [questionId]: newValue,
        };

        onChange?.(newAnswers);
        return newAnswers;
      });
    },
    [onChange]
  );

  return (
    <>
      <div className="text__center">
        <h2 className="text__l text__bold mg__bottom__m">お客さまアンケート</h2>
      </div>
      <p className="text__center__pc mg__bottom__m">
        サービス向上のため、アンケートのご協力をお願いいたします。
      </p>
      {questions.map((question) => (
        <Fragment key={question.id}>
          {question.type === SurveyType.radio ? (
            <SurveyRadioButtonGroup
              options={question.answerOptions}
              question={question.questionText}
              questionId={question.id}
              answer={answers[question.id]?.answer?.[0]}
              otherAnswer={answers[question.id]?.otherAnswer}
              onChange={handleChange}
              hasOtherSelection={question.hasOtherSelection}
            />
          ) : (
            <SurveyCheckboxes
              options={question.answerOptions}
              question={question.questionText}
              questionId={question.id}
              answers={answers[question.id]?.answer}
              otherAnswer={answers[question.id]?.otherAnswer}
              onChange={handleChange}
              hasOtherSelection={question.hasOtherSelection}
            />
          )}
        </Fragment>
      ))}
    </>
  );
}
