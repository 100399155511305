export interface PointHistoryDataEmptyBodyProps {
  inTable?: boolean;
  colSpan?: number;
}

export function PointHistoryDataEmptyBody({
  inTable = true,
  colSpan,
}: PointHistoryDataEmptyBodyProps): React.ReactNode {
  const contents = <p className="text__m mg__top__s">ポイント履歴はございません。</p>;

  return inTable ? (
    <tr>
      <td colSpan={colSpan} className="text__center">
        {contents}
      </td>
    </tr>
  ) : (
    contents
  );
}
