"use client";

import { Fragment, useState } from "react";

import { format } from "date-fns";
import Link from "next/link";

import { Hr } from "@/components/displays";
import Pagination from "@/components/displays/Pagination";
import { useParsedGetCustomerOrders } from "@/queries";
import { usePrefetchCallbacks } from "@/utils/hooks";

import { getDeliveryStatus, getPaymentAmount, getPaymentStatus, isDaibiki } from "./helpers";
import { PurchaseHistoryDataEmptyBody } from "./PurchaseHistoryDataEmptyBody";
import styles from "./PurchaseHistoryTable.module.scss";
import { HistoryDataLoadingBody } from "../HistoryDataLoadingBody";
import { PCHistoryTableWrapper } from "../PCHistoryTableWrapper";
import { SPHistoryTableWrapper } from "../SPHistoryTableWrapper";

export function PurchaseHistoryTable(): React.ReactNode {
  const [page, setPage] = useState(1);
  const { isLoading, data } = useParsedGetCustomerOrders(page);
  const { prefetchOrderDetailCallback } = usePrefetchCallbacks();

  const paginationPart = <Pagination onPageChange={setPage} totalPage={data?.meta.lastPage || 0} />;

  return (
    <>
      {/* PC版 */}
      <PCHistoryTableWrapper>
        <table className="text__center">
          <thead>
            <tr className="text__s">
              <th>注文番号</th>
              <th>注文日</th>
              <th>支払い状況</th>
              <th>配送状況</th>
              <th>支払い金額</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && <HistoryDataLoadingBody colSpan={5} />}
            {!isLoading && !data?.orders.length && <PurchaseHistoryDataEmptyBody colSpan={5} />}

            {data?.orders.map((order) => (
              <tr key={order.id}>
                <td className="text__left">
                  <Link
                    href={`/mypage/purchase_history/order_detail/${order.id}`}
                    className={styles.link}
                    onMouseEnter={prefetchOrderDetailCallback({ order_id: order.id })}
                    onFocus={prefetchOrderDetailCallback({ order_id: order.id })}
                  >
                    {order.id}
                  </Link>
                </td>
                <td>
                  <p>{format(order.createdAt, "yyyy/MM/dd")}</p>
                </td>
                <td>
                  <p className="text__bold">
                    <span>{getPaymentStatus(order)}</span>
                  </p>
                </td>
                <td>
                  <p className="text__bold">
                    <span>{getDeliveryStatus(order)}</span>
                  </p>
                </td>
                <td>
                  <p className="text__bold">{getPaymentAmount(order)}</p>
                  {isDaibiki(order) && (
                    <p className="text__s text__gray__dark mg__bottom__xs">※代引き手数料は別</p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {paginationPart}
      </PCHistoryTableWrapper>
      {/* end of PC版 */}

      {/* SP版 */}
      <SPHistoryTableWrapper>
        {!isLoading && !data?.orders.length && <PurchaseHistoryDataEmptyBody inTable={false} />}
        {data?.orders.map((order) => (
          <Fragment key={order.id}>
            <div className="pd__top__m">
              <div className="mg__bottom__m">
                <dl>
                  <dt>
                    <p className="wsnr">注文番号：</p>
                  </dt>
                  <dd>
                    <p className="text__right">
                      <Link
                        href={`/mypage/purchase_history/order_detail/${order.id}`}
                        className={styles.link}
                      >
                        {order.id}
                      </Link>
                    </p>
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <p>注文日：</p>
                  </dt>
                  <dd>
                    <p className="text__right">{format(order.createdAt, "yyyy/MM/dd")}</p>
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <p>支払い状況：</p>
                  </dt>
                  <dd>
                    <p className="text__right text__bold">
                      <span>{getPaymentStatus(order)}</span>
                    </p>
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <p>配送状況：</p>
                  </dt>
                  <dd>
                    <p className="text__right text__bold">
                      <span>{getDeliveryStatus(order)}</span>
                    </p>
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <p>支払い金額：</p>
                  </dt>
                  <dd>
                    <p className="text__right text__bold">{getPaymentAmount(order)}</p>
                  </dd>
                </dl>
                {isDaibiki(order) && (
                  <p className="text__s text__gray__dark text__right mg__bottom__xs">
                    ※代引き手数料は別
                  </p>
                )}
              </div>
            </div>
            <Hr noMargin />
          </Fragment>
        ))}
        {paginationPart}
      </SPHistoryTableWrapper>
      {/* end of SP版 */}
    </>
  );
}
