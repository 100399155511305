import { Column, Row } from "@/components/containers";
import { Image, Paper } from "@/components/displays";
import { Modal } from "@/components/feedbacks";
import { Button } from "@/components/inputs";

import styles from "./GiftCancellationAlertModal.module.scss";

export interface GiftCancellationAlertModalProps {
  open: boolean;
  deliveryGifts?: {
    imageUrl: string;
    name: string;
  }[];
  isLoading?: boolean;
  onClose: () => void;
  onSave: () => void;
}

export function GiftCancellationAlertModal({
  open,
  deliveryGifts,
  isLoading,
  onClose,
  onSave,
}: GiftCancellationAlertModalProps): React.ReactNode {
  return (
    <Modal
      displayCloseButton={false}
      open={open}
      closeModal={onClose}
      bodyClassName={styles.body}
      className={styles.modal}
      footer={
        <Row gap={10}>
          <Button variants="gray" onClick={onClose} disabled={isLoading}>
            キャンセル
          </Button>
          <Button variants="yellow" onClick={onSave} disabled={isLoading}>
            変更を保存
          </Button>
        </Row>
      }
    >
      <Paper>
        <Column gap={20}>
          <div className="text__l text__bold">変更を保存しますか？</div>
          <p>ご注文を停止すると、お届け予定のギフトも自動的に取り消されます。</p>
          <Paper padding="fixed20" style={{ background: "#F0F0F0" }}>
            <Column gap={20}>
              <div className="text__bold">お届け予定のギフト</div>
              {deliveryGifts?.map((gift) => (
                <Row gap={10} key={gift.name} align="center">
                  <Image src={gift.imageUrl} alt={gift.name} size={{ width: 80, height: 80 }} />
                  <p className="text__bold">{gift.name}</p>
                </Row>
              ))}
            </Column>
          </Paper>
        </Column>
      </Paper>
    </Modal>
  );
}
