"use client";
import { useMemo } from "react";

import clsx from "clsx";
import { addDays, format, isWithinInterval, parseISO } from "date-fns";
import { uniqBy } from "ramda";

import { Column, Row } from "@/components/containers";
import { Chip, Image } from "@/components/displays";
import { Button, Selector } from "@/components/inputs";
import { useParsedGetMiles } from "@/queries";
import { formatCurrency } from "@/utils/currency";
import { usePreviousMemo } from "@/utils/hooks";

import { makeQuantityOptions } from "./helper";
import styles from "./SubscriptionProductItem.module.scss";
import { SubscriptionProduct } from "../helper";

export interface SubscriptionProductItemProps {
  product: SubscriptionProduct;
  deliveryDate: Date | null;
  isFirstSubscriptionOrder: boolean;
  pastDiscountRate: boolean;
  onChangeQuantity?: (quantity: number) => void; // undefinedだと変更不可
}

const newBrandOffsetDays = 4; // 新商品の割引は、4日後まで表示する

export function SubscriptionProductItem({
  product,
  deliveryDate,
  isFirstSubscriptionOrder,
  pastDiscountRate,
  onChangeQuantity,
}: SubscriptionProductItemProps): React.ReactNode {
  const { isDiscountNewProductRank } = useParsedGetMiles();
  const selectOptions = usePreviousMemo<{ value: number; label: string }[]>(
    (prev = []) => {
      const options = makeQuantityOptions(product).map((value) => ({
        value,
        label: String(value),
      }));
      // 選択肢が消えないように、直前の選択肢と結合して重複を除去
      return uniqBy((item) => item.value, [...prev, ...options]).sort((a, b) => a.value - b.value);
    },
    [product]
  );

  const isProductBrandNewDiscount = useMemo(() => {
    const brandNewDiscount = product.brandNewDiscount;
    if (!brandNewDiscount || !deliveryDate || !isDiscountNewProductRank) return false;
    const startDate = addDays(parseISO(brandNewDiscount.startDate.formatted), newBrandOffsetDays);
    const endDate = addDays(parseISO(brandNewDiscount.endDate.formatted), newBrandOffsetDays);
    return isWithinInterval(deliveryDate, { start: startDate, end: endDate });
  }, [deliveryDate, product.brandNewDiscount, isDiscountNewProductRank]);

  const price = useMemo(() => {
    if (isProductBrandNewDiscount && product.productBrandNewDiscountPrice) {
      return Number(product.productBrandNewDiscountPrice);
    }
    return pastDiscountRate || isFirstSubscriptionOrder
      ? product.firstSubscriptionPrice
      : product.afterSecondSubscriptionPrice;
  }, [
    isFirstSubscriptionOrder,
    pastDiscountRate,
    isProductBrandNewDiscount,
    product.afterSecondSubscriptionPrice,
    product.firstSubscriptionPrice,
    product.productBrandNewDiscountPrice,
  ]);

  const totalPrice = useMemo(() => {
    if (product.quantity === 0) {
      return 0;
    }
    if (isProductBrandNewDiscount && product.productBrandNewDiscountTotalPrice) {
      return Number(product.productBrandNewDiscountTotalPrice);
    }

    if (pastDiscountRate || isFirstSubscriptionOrder) {
      return Number(product.firstSubscriptionTotalPrice);
    } else {
      return Number(product.afterSecondSubscriptionTotalPrice);
    }
  }, [pastDiscountRate, isFirstSubscriptionOrder, isProductBrandNewDiscount, product]);

  const newBrandMessage = useMemo(() => {
    if (!product.brandNewDiscount || !isProductBrandNewDiscount) return;
    const endDate = addDays(
      parseISO(product.brandNewDiscount.endDate.formatted),
      newBrandOffsetDays
    );
    return `${format(endDate, "y年M月d日")}お届け分まで、ランク特典で30%オフ`;
  }, [isProductBrandNewDiscount, product.brandNewDiscount]);

  return (
    <Column className={styles.root}>
      <Row className={styles.itemHeading}>
        {product.images && product.images.length > 0 && (
          <Image
            src={product.images[product.variantImageIndex ?? 0]}
            alt={product.title ?? product.variantTitle}
            size={{ width: 80, height: 80 }}
            className={styles.productThumbnail}
          />
        )}
        <div className={styles.productInfo}>
          <Column>
            {product.showTitle && <p className="text__s">{product.title}</p>}
            <Row>
              <span className="text__m">{product.variantTitle}</span>
              {product.isNew && (
                <span className={clsx("text__m", "text__red", "text__bold", styles.newTag)}>
                  NEW
                </span>
              )}
            </Row>
          </Column>

          <Row className={clsx("mg__top__s", styles.unitPriceContainer)}>
            {isProductBrandNewDiscount ? (
              <Chip className={styles.discountChip} color="red" variant="filled">
                30%オフ
              </Chip>
            ) : (
              <Chip className={styles.discountChip} color="red" variant="outlined">
                割引価格
              </Chip>
            )}
            <span className={clsx("text__m", "text__red", "text__bold", styles.unitPrice)}>
              {formatCurrency(price)}
            </span>
            <span className="text__s">/{product.meal}</span>
          </Row>
        </div>
      </Row>
      {newBrandMessage && (
        <Row>
          <p className="text__s text__bold text__red mg__top__xs">{newBrandMessage}</p>
        </Row>
      )}
      <Row className={clsx(styles.itemDescription)}>
        <p className="text__s text__gray__dark mg__top__s mg__bottom__xs">{product.description}</p>
      </Row>
      <Row className={styles.itemBottom}>
        <Row className={styles.itemBottomActions}>
          <Selector
            value={product.quantity}
            options={selectOptions}
            defaultValue={product.quantity}
            className={clsx(product.isChanged && styles.changedSelector)}
            disabled={!onChangeQuantity}
            onChange={onChangeQuantity}
          />
          <span>袋</span>
          {product.quantity > 0 && (
            <Button
              variants="gray"
              size="sm"
              rounded
              disabled={!onChangeQuantity}
              onClick={onChangeQuantity ? () => onChangeQuantity(0) : undefined}
            >
              削除
            </Button>
          )}
        </Row>
        <span className={clsx("text__l", "text__bold")}>{formatCurrency(totalPrice)}*</span>
      </Row>
      {product.cautions.length > 0 && (
        <Column className="pd__top__m">
          {product.cautions.map((caution) => (
            <p
              key={caution}
              className="text__s text__red"
              dangerouslySetInnerHTML={{ __html: caution }}
            ></p>
          ))}
        </Column>
      )}
      {product.annotation.length > 0 && (
        <Column className="pd__top__s">
          {product.annotation.map((annotation) => (
            <p key={annotation} className="text__s text__gray__dark">
              {annotation}
            </p>
          ))}
        </Column>
      )}
    </Column>
  );
}
