import { useMemo } from "react";

import { useGetValidDates } from "@/generated/open-api/subscription/subscription";

export const useParsedGetValidDates = () => {
  const { data, ...rest } = useGetValidDates();
  const validDates = useMemo(() => {
    return { validDates: data?.valid_dates ?? [] };
  }, [data?.valid_dates]);
  return { data: validDates, ...rest };
};
