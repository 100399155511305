"use client";

import { Fragment, useState } from "react";

import clsx from "clsx";

import { Column } from "@/components/containers";
import { Hr, LoadingOverlay, Paper } from "@/components/displays";
import Pagination from "@/components/displays/Pagination";
import { useParsedGetMileHistories, useParsedGetMileSummary } from "@/queries";

import styles from "./MileHistories.module.scss";
import { MileHistoryItem } from "./MileHistoryItem";
import { MileInTradeInfo } from "./MileInTradeInfo";

interface MileHistoriesProps {
  titleClassName?: string;
}

export function MileHistories({ titleClassName }: MileHistoriesProps): React.ReactNode {
  const [page, setPage] = useState(1);
  const { data, isLoading: isGettingMileHistories } = useParsedGetMileHistories(page);
  const { data: mileSummary, isLoading: isGettingMileSummary } = useParsedGetMileSummary();
  const { data: mileHistories = [], meta: paginate } = data || {};
  const totalPage = paginate?.lastPage || 0;

  return (
    <LoadingOverlay isLoading={isGettingMileSummary || isGettingMileHistories}>
      <Paper padding="responsive">
        <Column gap={20}>
          <h3 className={clsx(styles.title, titleClassName)}>マイル獲得・交換履歴</h3>
          {(mileSummary?.tradeMiles ?? 0) > 0 && (
            <MileInTradeInfo mileInTrade={mileSummary?.tradeMiles ?? 0} />
          )}
          <Column gap={20}>
            <Hr noMargin />
            {!isGettingMileHistories && mileHistories.length === 0 ? (
              <p className="text__m text__center mg__bottom__m">
                マイル獲得・交換履歴が存在しません
              </p>
            ) : (
              mileHistories.map((mileHistory, index) => (
                <Fragment key={index}>
                  <MileHistoryItem
                    amount={mileHistory.point}
                    date={mileHistory.date}
                    description={mileHistory.text}
                    label={mileHistory.label}
                  />
                  <Hr noMargin />
                </Fragment>
              ))
            )}
            <Pagination totalPage={totalPage} onPageChange={setPage} />
          </Column>
          <p className={clsx("text__gray__dark", styles.notes)}>※日付は反映日です。</p>
        </Column>
      </Paper>
    </LoadingOverlay>
  );
}
