import { PropsWithChildren } from "react";

import clsx from "clsx";

import styles from "./PCHistoryTableWrapper.module.scss";

/**
 * TODO: Tableコンポーネントを作って汎用化する
 */
export function PCHistoryTableWrapper({ children }: PropsWithChildren) {
  return <div className={clsx(styles.wrapper, "clear__sp")}>{children}</div>;
}
