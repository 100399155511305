"use client";

import { useCallback, useState } from "react";

import { LinkButton } from "@/components";
import { AfterUnsubscribeSurveyModal } from "@/components/template/SurveyForms";
import {
  useParsedGetCancellationSurvey,
  useParsedGetCustomer,
  useParsedGetSubscription,
} from "@/queries";

export function UnsubscribeSection(): React.ReactNode {
  const { data: customer } = useParsedGetCustomer();
  const { data: unsubscribeSurvey } = useParsedGetCancellationSurvey();
  const {
    data: { subscription, freezeSubscription },
  } = useParsedGetSubscription();
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const handleOpenSurveyModal = useCallback(() => {
    setShowSurveyModal(true);
  }, []);
  const handleCloseSurveyModalWithRedirect = useCallback(async () => {
    setShowSurveyModal(false);

    // 解約後キャッシュを削除したいため、location.hrefでリダイレクトする
    window.location.href = "/mypage";
  }, []);

  return (
    <>
      <section className="mg__bottom__xl">
        <div className="col-12 col-m-10 col-offset-m-1">
          <p className="text__xl text__bold text__center pd__top__l">ご解約のご案内</p>
          <p className="text__m text__left text__center__pc mg__top__m">
            継続コースをご利用いただきありがとうございます。
            <br />
            ご解約をご希望のかたは「解約する」より、ご解約をお願いいたします。
          </p>
          <div className="text__center mg__bottom__m mg__top__m">
            <LinkButton arrow="angle__left" color="yellow" href="/mypage">
              解約せずマイページに戻る
            </LinkButton>
          </div>
          <div className="text__center">
            <button
              type="button"
              className="btn inline round yellow text__m"
              onClick={handleOpenSurveyModal}
            >
              解約する
            </button>
          </div>
        </div>
      </section>
      <AfterUnsubscribeSurveyModal
        modalProps={{ open: showSurveyModal, closeModal: handleCloseSurveyModalWithRedirect }}
        email={customer.email}
        questions={unsubscribeSurvey?.surveyList || []}
        subscriptionId={subscription?.id || freezeSubscription?.id || ""}
      />
    </>
  );
}
