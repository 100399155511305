"use client";
import clsx from "clsx";
import Link from "next/link";

import { Column, Row } from "@/components/containers";
import { Chip, Hr } from "@/components/displays";
import { orderDeliveryStatusLabels } from "@/models/orderWithDeliveryDate/consts";
import { PaymentMethod } from "@/models/payment/consts";
import { SubscriptionModel } from "@/models/subscription/type";
import { useParsedGetOrderWithDeliveryDate } from "@/queries";

import styles from "./DeliverySchedule.module.scss";
import { DeliveryScheduleForm } from "./DeliveryScheduleForm";
import { DeliveryScheduleFormSchemaValue } from "./DeliveryScheduleForm/schema";
import { orderDeliveryStatusColors } from "./helper";
import { Temperature } from "../types";

interface DeliveryScheduleProps {
  subscription: SubscriptionModel | undefined;
  values: Partial<DeliveryScheduleFormSchemaValue>;
  onChange: (values: Partial<DeliveryScheduleFormSchemaValue>) => void;
  onSkip: (() => void) | undefined;
  canEdit?: boolean;
  temperature: Temperature;
  warningMessages: string[];
  cartTotalPrice: number;
}

export function DeliverySchedule({
  subscription,
  values,
  onChange,
  onSkip,
  canEdit = true,
  temperature,
  warningMessages,
  cartTotalPrice,
}: DeliveryScheduleProps): React.ReactNode {
  const orders = subscription?.orders ?? [];
  const lastOrder = orders[orders.length - 1];
  const { data: orderData } = useParsedGetOrderWithDeliveryDate(lastOrder?.id);

  /**
   * 常温であり、代引き以外の支払い方法である
   */
  const normalTempPrepaid =
    temperature === "normal" && subscription?.paymentMethod !== PaymentMethod.daibiki;

  return (
    <Column className={styles.root}>
      <p className={clsx("text__m", "mg__bottom__s")}>直近のご注文</p>
      {subscription ? (
        <>
          {orderData ? (
            <Column className={styles.latestOrder}>
              <Row className={clsx("text__m", "text__bold")}>
                <p>{orderData.displayDeliveryDate}</p>
                <Chip
                  className={styles.deliveryStatusChip}
                  color={orderDeliveryStatusColors[orderData.deliveryStatus]}
                >
                  {orderDeliveryStatusLabels[orderData.deliveryStatus]}
                </Chip>
              </Row>
              <Link
                className={clsx("text__s", styles.orderLink, "mg__top__s")}
                href={`/mypage/order_detail?order_id=${lastOrder.id}`}
              >
                ご注文の詳細を見る
              </Link>
            </Column>
          ) : (
            <p className="text__m text__bold">直近のご注文はございません。</p>
          )}
          {/* 商品がない場合は休眠扱いなので、配送スケジュールの調整を不可にする */}
          {subscription.products.length > 0 && (
            <>
              <Hr className={styles.orderDivider} />
              <DeliveryScheduleForm
                subscription={subscription}
                onChange={onChange}
                onSkip={onSkip}
                values={values}
                paymentMethod={subscription?.paymentMethod}
                canEdit={canEdit}
                temperature={temperature}
                warningMessages={warningMessages}
                cartTotalPrice={cartTotalPrice}
              />
              <Column className={styles.policy}>
                {normalTempPrepaid && (
                  <>
                    <p>
                      ※置き配を利用される場合、
                      <a href="/terms?show_tab=5" target="_blank">
                        置き配規約
                      </a>
                      に同意いただいたことになります。
                    </p>
                    <p>
                      ※常温配送対面受け取り希望でも、ご不在時は宅配BOXにお届けする場合がございます。あらかじめご了承ください（東京都・埼玉県・神奈川県・千葉県が対象）
                    </p>
                  </>
                )}
                <p>
                  ※次回のお届け日を変更すると、次々回のお届け日は、 その4週間後に 設定されます。
                </p>
                <p>※次回配送日は、本日から59日後まで変更可能です。</p>
                <p>
                  ※商品の返品・交換・キャンセル及び解約手続きについては
                  <a
                    href="https://basefood.zendesk.com/hc/ja/articles/11869877100569"
                    target="_blank"
                  >
                    こちら
                  </a>
                  。
                </p>
              </Column>
            </>
          )}
        </>
      ) : (
        <p className="text__m text__bold">直近のご注文はございません。</p>
      )}
    </Column>
  );
}
