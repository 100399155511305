import React from "react";

import clsx from "clsx";

import { Column, Row } from "@/components/containers";
import { Hr, Image } from "@/components/displays";
import { Modal } from "@/components/feedbacks";
import { MileIcon } from "@/components/icons";
import { Button } from "@/components/inputs";

import styles from "./MileGiftTradeModal.module.scss";

export interface MileGiftTradeModalProps {
  open: boolean;
  isOperationPending?: boolean;
  onNegative: () => void;
  onPositive: () => void;

  type: "trade" | "abort";
  giftTitle: string;
  giftDescription: string;
  giftImageUrl: string;
  beforePrice: number;
  afterPrice: number;
}

const mileGiftTradeModalParams = {
  trade: {
    title: "このギフトに交換しますか？",
    negativeButtonLabel: "キャンセル",
    positiveButtonLabel: "交換する",
    cation: "※交換したギフトは、次回の継続コースに同梱してお届けします。",
  },
  abort: {
    title: "このギフトの交換を取り消しますか？",
    negativeButtonLabel: "やめる",
    positiveButtonLabel: "取り消す",
    cation: null,
  },
} as const;

export function MileGiftTradeModal({
  open,
  isOperationPending,
  onNegative,
  onPositive,
  type = "trade",
  giftTitle,
  giftDescription,
  giftImageUrl,
  beforePrice,
  afterPrice,
}: MileGiftTradeModalProps) {
  const params = mileGiftTradeModalParams[type];
  return (
    <Modal
      displayCloseButton={false}
      open={open}
      closeModal={onNegative}
      className={styles.modal}
      header={
        <Row className={styles.header}>
          <h3 className="text__center">{params.title}</h3>
        </Row>
      }
      footer={
        <Row gap={10}>
          <Button variants="gray" onClick={onNegative} disabled={isOperationPending}>
            {params.negativeButtonLabel}
          </Button>
          <Button variants="yellow" onClick={onPositive} disabled={isOperationPending}>
            {params.positiveButtonLabel}
          </Button>
        </Row>
      }
    >
      <Column className={styles.body}>
        <Image src={giftImageUrl} alt="商品画像" size={{ width: 200, height: 200 }} />
        <h4 className={styles.giftTitle}>{giftTitle}</h4>
        <p className={styles.description}>{giftDescription}</p>
        <Hr className={styles.divider} />
        <Row className={styles.mileUsage}>
          <Row className={styles.mileUsageTitle}>
            <MileIcon />
            <span>保有マイル</span>
          </Row>
          <Row className={styles.mileUsageDetail}>
            <span>{beforePrice.toLocaleString()}</span>
            <div className={styles.arrow} />
            <span className={styles.mileUsageAfter}>{afterPrice.toLocaleString()}</span>
          </Row>
        </Row>
        {params.cation && (
          <>
            <Hr className={styles.divider} />
            <p className={clsx("text__s", "text__gray__dark")}>{params.cation}</p>
          </>
        )}
      </Column>
    </Modal>
  );
}
