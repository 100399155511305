import { RankNameTransformed } from "@/generated/open-api/schemas";

export const RANK_MAP: Record<RankNameTransformed, { rankImage: string; rankNameJp: string }> = {
  Economy: {
    rankImage: "https://asset.basefood.co.jp/images/parts/badge_economy.svg",
    rankNameJp: "エコノミークラス",
  },
  Business: {
    rankImage: "https://asset.basefood.co.jp/images/parts/badge_business.svg",
    rankNameJp: "ビジネスクラス",
  },
  First: {
    rankImage: "https://asset.basefood.co.jp/images/parts/badge_first.svg",
    rankNameJp: "ファーストクラス",
  },
  Vip: {
    rankImage: "https://asset.basefood.co.jp/images/parts/badge_vip.svg",
    rankNameJp: "VIPクラス",
  },
} as const;
