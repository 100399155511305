import { useMemo } from "react";

import clsx from "clsx";

import { LoadingOverlay } from "@/components/displays";
import { useDeliveryDate } from "@/components/template/mypage/MyPageIndex/helpers";
import { useParsedGetSubscription } from "@/queries";

import { DeliveryAddition } from "./DeliveryAddition";
import styles from "./DeliverySchedule.module.scss";
import { DeliveryScheduleDetail } from "./DeliveryScheduleDetail";

export function DeliverySchedule(): React.ReactNode {
  const { data: subscription } = useParsedGetSubscription();
  const {
    normalDeliveryDate,
    freezeDeliveryDate,
    orderWithDeliveryDate,
    freezeOrderWithDeliveryDate,
    isFetchingNormalDeliveryDate,
    isFetchingFreezeDeliveryDate,
  } = useDeliveryDate(subscription);
  const showComebackSubscription =
    !subscription.subscription &&
    !subscription.freezeSubscription &&
    subscription.comebackSubscriptionProducts.length > 0;
  const showComebackFreezeSubscription =
    !subscription.subscription &&
    !subscription.freezeSubscription &&
    subscription.comebackFreezeSubscriptionProducts.length > 0;

  // 定期契約の表示順を決定
  const [normalOrder, freezeOrder] = useMemo(() => {
    if (
      normalDeliveryDate &&
      freezeDeliveryDate &&
      normalDeliveryDate.deliveryDate > freezeDeliveryDate.deliveryDate
    ) {
      return [2, 1];
    }

    if (!normalDeliveryDate && freezeDeliveryDate) {
      return [2, 1];
    }

    return [1, 2];
  }, [normalDeliveryDate, freezeDeliveryDate]);

  return (
    <section className={clsx("bg__yellow", styles.section, styles.deliverySchedule)}>
      <h3 className={"text__l text__bold mg__bottom__m"}>配送予定の継続コース</h3>
      <LoadingOverlay isLoading={isFetchingNormalDeliveryDate || isFetchingFreezeDeliveryDate}>
        <div className={styles.subscription}>
          {subscription.subscription ? (
            <div className={styles.subscriptionRelative} style={{ order: normalOrder }}>
              <DeliveryScheduleDetail
                temperature="normal"
                subscription={subscription.subscription}
                deliveryDate={normalDeliveryDate}
                orderWithDeliveryDate={orderWithDeliveryDate}
              />
            </div>
          ) : (
            <div style={{ order: normalOrder }}>
              <DeliveryAddition
                temperature="normal"
                showComeback={showComebackSubscription}
                products={subscription.comebackSubscriptionProducts}
              />
            </div>
          )}
          {subscription.freezeSubscription ? (
            <div className={styles.subscriptionRelative} style={{ order: freezeOrder }}>
              <DeliveryScheduleDetail
                temperature="freeze"
                subscription={subscription.freezeSubscription}
                deliveryDate={freezeDeliveryDate}
                orderWithDeliveryDate={freezeOrderWithDeliveryDate}
              />
            </div>
          ) : (
            <div style={{ order: normalOrder }}>
              <DeliveryAddition
                temperature="freeze"
                showComeback={showComebackFreezeSubscription}
                products={subscription.comebackFreezeSubscriptionProducts}
                isFreezeDeliveryArea={subscription.freezeDeliveryArea}
              />
            </div>
          )}
        </div>
      </LoadingOverlay>
    </section>
  );
}
