"use client";

import {
  calcSubscriptionCart,
  getCustomerOrdersSummery,
  getMyPageSummary,
} from "@/generated/axios-functions/payseAPI";
import { GetMyPageSummary200SurveyAnswers, Product, TimeStamp } from "@/generated/open-api/schemas";
import { CustomerModel } from "@/models/customer/type";
import { MileModel } from "@/models/mile/type";
import { SubscriptionResponseModel, SubscriptionModel } from "@/models/subscription/type";
import { getParsedOrderWithDeliveryDate } from "@/queries";
import { sumBy } from "@/utils/array";
import { pushGtmEvent } from "@/utils/gtm";
import { objectEntries, objectKeys } from "@/utils/object";

type ProductCountMap = {
  [key: string]: number;
};

type ProductNameMap = {
  [key: string]: string;
};

const productNameMap: ProductNameMap = {
  plain_food: "プレーン",
  choco_food: "チョコレート",
  maple_food: "メープル",
  cinnamon_food: "シナモン",
  curry_food: "カレー",
  fettucine_food: "フェットチーネ",
  asian_food: "アジアン",
  bolognese_food: "ボロネーゼ",
  tarako_food: "たらこ",
  mushroom_food: "きのこクリーム",
  pancake_mix_food: "パンケーキミックス",
  yakisoba_food: "ソース焼きそば",
  source_food: "特製ソース4食セット",
  slice_plain_food: "ミニ食パン・プレーン",
  slice_plain_raisin_food: "ミニ食パン・レーズン",
  cocoa_food: "ココア",
  earlgrey_food: "アールグレイ",
  macha_food: "抹茶",
  coconut_food: "ココナッツ",
  sweetpotato_food: "さつまいも",
};

export const countProducts = (productCountMap: ProductCountMap, products: Product[]) => {
  return objectKeys(productCountMap).reduce((acc, key) => {
    products.forEach((product) => {
      if (product.variant_title === productNameMap[key.toString()]) {
        acc[key] += product.quantity ?? 0;
      }
    });
    return acc;
  }, productCountMap);
};

const buildCartBodyParams = (subscription?: SubscriptionModel) => {
  if (!subscription) return null;

  return {
    products:
      subscription?.products.map((product) => {
        return {
          variant_id: product.variantId.toString(),
          quantity: product.quantity.toString(),
          subscription: true,
        };
      }) || [],
    use_point: subscription?.nextUsePoint,
    use_point_type: subscription?.nextUsePointType,
    delivery_date: subscription?.nextOrderArrivalDate,
    payment_method: subscription?.paymentMethod,
    coupon: subscription?.coupon,
  };
};

const convertTimeStampToDate = (timeStamp: TimeStamp): Date => {
  const { year, month, day, hour, minute, second } = timeStamp;
  return new Date(year, month, day, hour, minute, second);
};

const convertDateStringToDate = (date?: string): Date | undefined => {
  return date ? new Date(date.replaceAll("/", "-") + "T00:00:00+09:00") : undefined;
};

export const completeAnalytics = async (
  customer: CustomerModel,
  subscription: SubscriptionResponseModel,
  mile: MileModel,
  point?: number
) => {
  // orderWithDeliveryDate取得前に実行される可能性があるので、ここで改めて取得
  const orders = subscription.subscription?.orders;
  const orderId = orders?.length ? orders[orders.length - 1].id : undefined;
  const orderWithDeliveryDate = await getParsedOrderWithDeliveryDate(orderId);
  const ordersSummary = await getCustomerOrdersSummery();
  const myPageSummary = await getMyPageSummary();

  const cartParams = buildCartBodyParams(subscription.subscription);
  const res = cartParams ? await calcSubscriptionCart(cartParams) : null;
  const cart = res?.cart;

  const freezeCartParams = buildCartBodyParams(subscription.freezeSubscription);
  const freezeRes = freezeCartParams ? await calcSubscriptionCart(freezeCartParams) : null;
  const freezeCart = freezeRes?.cart;

  if (!cart && !freezeCart) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const karteValues: any = {
    event: "pushKarte",
    user_id: customer.id,
    signup_date: convertTimeStampToDate(customer.createdAt),
    name: `${customer.lastName}${customer.firstName}`,
    email: customer.email,
    postal_code: customer.address?.zip ?? "",
    email_consent: customer.acceptsMarketing,
    point: point ?? 0,
    mile: mile?.totalMile || 0,
    rank: mile?.rankName || 0,
    total_purchase_price: ordersSummary.total_purchase_price,
    total_food_count: ordersSummary.total_food_count,
    previous_food_count: subscription.subscription?.orders?.length
      ? sumBy(subscription.subscription?.orders[0].orderProducts ?? [], "quantity")
      : 0,
    normal_amount_next_purchase: Number(cart?.after_second_total_price) ?? 0,
    frozen_amount_next_purchase: Number(freezeCart?.after_second_total_price) ?? 0,
    normal_next_total_line_items_price: Number(cart?.line_total_price) ?? 0,
    frozen_next_total_line_items_price: Number(freezeCart?.line_total_price) ?? 0,
    normal_next_food_count: Number(cart?.total_meal_num),
    frozen_next_food_count: Number(freezeCart?.total_meal_num),
    normal_scheduled_delivery_date:
      convertDateStringToDate(subscription.subscription?.nextOrderArrivalDate) ?? "",
    frozen_scheduled_delivery_date:
      convertDateStringToDate(subscription.freezeSubscription?.nextOrderArrivalDate) ?? "",
    normal_coupon_code: cart?.coupon ?? "",
    frozen_coupon_code: freezeCart?.coupon ?? "",
    delivery_status: orderWithDeliveryDate?.deliveryStatus ?? "",
    payment_method:
      subscription.subscription?.paymentMethod ??
      subscription.freezeSubscription?.paymentMethod ??
      "",
    invite_count: myPageSummary.inviteCount,
  };

  // surveyAnswerの追加
  if (myPageSummary.surveyAnswers) {
    // 1,3,4,5は単一回答の質問
    [1, 3, 4, 5].forEach((i) => {
      karteValues[`survey${i}`] =
        myPageSummary.surveyAnswers?.[`survey${i}` as keyof GetMyPageSummary200SurveyAnswers]
          ?.answer_no?.[0] ?? "";
    });

    // 8,9は複数回答の質問
    [8, 9].forEach((i) => {
      karteValues[`survey${i}`] =
        myPageSummary.surveyAnswers?.[`survey${i}` as keyof GetMyPageSummary200SurveyAnswers]
          ?.answer_no ?? "";
    });
  }

  // 商品ごとの購入数を追加
  const initialCountMap: ProductCountMap = Object.fromEntries(
    Object.keys(productNameMap).map((key) => [key, 0])
  );
  const countedMap = countProducts(
    initialCountMap,
    (cart?.products ?? []).concat(freezeCart?.products ?? [])
  );
  objectEntries(countedMap).forEach(([key, value]) => {
    karteValues[`${key}_count`] = value;
  });

  pushGtmEvent(karteValues);
};
