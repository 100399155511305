import { useMemo } from "react";

import { CartModel } from "@/models/cart/type";

export function useChangedFields(cart: CartModel | undefined, defaultCart: CartModel | undefined) {
  return useMemo(
    () => ({
      coupon: (cart?.coupon || "") !== (defaultCart?.coupon || ""),
      point: cart?.usePointType !== (defaultCart?.usePointType ?? "none"),
      lineTotalPrice: cart?.lineTotalPrice !== (defaultCart?.lineTotalPrice ?? 0),
      totalPrice:
        (cart?.lineTotalPrice !== 0
          ? (cart?.totalPrice ?? 0) + (cart?.totalPriceCashDeliveryFee ?? 0)
          : 0) !==
        (defaultCart?.lineTotalPrice !== 0
          ? (defaultCart?.totalPrice ?? 0) + (defaultCart?.totalPriceCashDeliveryFee ?? 0)
          : 0),
    }),
    [cart, defaultCart]
  );
}
