import { TooltipIcon } from "@/components/displays";
import { RankNameTransformed } from "@/generated/open-api/schemas";
import rankStyles from "@/style/rankColor.module.scss";

export interface NutrientTooltipIconProps {
  rankName: RankNameTransformed;
}

export function NutrientTooltipIcon({ rankName }: NutrientTooltipIconProps): React.ReactNode {
  return (
    <TooltipIcon
      id="question"
      className={rankStyles[`${rankName}SecondaryFontColor`]}
      tooltipTitle="栄養素・食材の数値について"
      tooltipCloseButtonText="OK"
      tooltips={`累計食数を「BASE BREAD チョコレート」
1袋分の各栄養成分値を元に算出し、食材例
で換算したものです。各食材の栄養成分は
八訂食品栄養成分表を参照し、各食材重量は
「食品の栄養とカロリー事典 第3版 |
女子栄養大学出版部 」を参照しています。`}
    />
  );
}
