"use client";

import Link from "next/link";

import { Image } from "@/components/displays";
import { useIsAppWebView } from "@/utils/hooks";

export function UnsubscriptionHeader(): React.ReactNode {
  const isAppWebView = useIsAppWebView();

  if (isAppWebView) {
    return null;
  }

  return (
    <div className="col-12 pd__top__m">
      <Link href="/">
        <Image
          className="logo"
          src="https://asset.basefood.co.jp/images/logo/logo-color.svg"
          alt="ベースフード株式会社"
          size={{ width: 184, height: 30 }}
        />
      </Link>
    </div>
  );
}
