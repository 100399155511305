import React from "react";

import clsx from "clsx";

import { Column } from "@/components/containers";
import { Image } from "@/components/displays";
import { Hr } from "@/components/displays";
import { Button } from "@/components/inputs";

import styles from "./BoughtTooMuch.module.scss";
import { RetentionContentProps } from "../common";

export function BoughtTooMuch({ onAction }: RetentionContentProps): JSX.Element {
  return (
    <Column className={styles.root}>
      <p className={clsx("text__l", "text__center", "text__bold", "mg__bottom__m")}>
        食べる量にあわせて
        <br />
        個数を調整しませんか？
      </p>
      <Image
        alt="商品選択"
        aspectRatio="443 / 200"
        className={styles.image}
        containerClassName={styles.imageContainer}
        src="https://asset.basefood.co.jp/images/ph/illust_pick_outline.png"
      />
      <p className={clsx("text__m", "text__left", "mg__top__m", "mg__bottom__m")}>
        マイページから、毎回お届けする継続コースの内容を自由に変更できます。継続することでからだの変化を実感しましょう！
      </p>
      <div className={clsx("text__center", "mg__top__m", "mg__bottom__m")}>
        <Button variants="yellow" rounded onClick={() => onAction({ type: "change-products" })}>
          個数を調整する
        </Button>
      </div>
      <Hr className={styles.hr} />
      <p className={clsx("text__m", "text__bold", "pd__top__m", "mg__bottom__m")}>
        マイページでの個数変更方法
      </p>
      <ol className={clsx("mg__bottom__m", "text__left")}>
        <li>1. 商品の個数を変更する。</li>
        <li>2. 画面下部の「変更を保存」をタップする。</li>
      </ol>
      <Image
        alt="スマートフォンの画面"
        aspectRatio="443 / 871"
        className={styles.image}
        src="https://asset.basefood.co.jp/images/ph/ph_skip_reason_change_amount_0805.png"
        containerClassName={styles.imageContainer}
      />
      <div className={clsx("text__center", "mg__top__m")}>
        <Button variants="yellow" rounded onClick={() => onAction({ type: "change-products" })}>
          個数を調整する
        </Button>
      </div>
    </Column>
  );
}
