"use client";
import React from "react";

import { Link, LinkProps } from "react-scroll";

import styles from "./MileRankButton.module.scss";

interface MileRankButtonProps extends Omit<LinkProps, "ref"> {}

export function MileRankButton({ children, ...rest }: MileRankButtonProps) {
  return (
    <Link className={styles.root} smooth {...rest}>
      <p className={styles.title}>{children}</p>
      <div className={styles.icon} />
    </Link>
  );
}
