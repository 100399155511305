import { LoadingOverlay } from "@/components/displays";

export interface HistoryDataLoadingBodyProps {
  colSpan?: number;
}

export function HistoryDataLoadingBody({ colSpan }: HistoryDataLoadingBodyProps): React.ReactNode {
  return (
    <tr>
      <td colSpan={colSpan} className="text__center">
        <LoadingOverlay isLoading>
          <p className="text__m mg__top__s">注文・ポイント履歴を読込中です。</p>
        </LoadingOverlay>
      </td>
    </tr>
  );
}
