import React from "react";

import clsx from "clsx";

import { Column } from "@/components/containers";
import { Image } from "@/components/displays";

import styles from "./Weariness.module.scss";

export function Weariness() {
  return (
    <Column className={styles.root}>
      <p className={clsx("text__l", "text__bold", "mg__bottom__m")}>
        BASE BREADにぴったり
        <br />
        マストアイテムをご紹介！
      </p>
      <p className={clsx("text__m", "text__left", "mg__top__m", "mg__bottom__m")}>
        そのまま食べてもおいしいBASE BREADを＋αでさらにおいしく。
      </p>
      <Column className={styles.recommendItem}>
        <div className={clsx(styles.batch, "bg__red")}>
          <span className={clsx("text__white", "text__bold")}>
            全種類に
            <br />
            おすすめ
          </span>
        </div>
        <Image
          className={styles.image}
          containerClassName={styles.recommendItemImageContainer}
          aspectRatio="140 / 101"
          alt="フィラデルフィアクリームチーズ"
          src="https://asset.basefood.co.jp/images/ph/ph_philadelphia.jpg"
        />
        <p className={clsx("text__s", "text__bold")}>フィラデルフィア</p>
        <p className={clsx("text__xl", "text__bold")}>クリームチーズ</p>
        <p className={clsx("text__m", "text__left", "mg__top__s")}>
          ハチミツを合わせたり、生ハムを合わせたり、いろんなアレンジが楽しめます。
        </p>
      </Column>
      <Image
        className={styles.image}
        containerClassName={styles.recipeImageContainer}
        aspectRatio="503 / 790"
        alt="レシピ"
        src="https://asset.basefood.co.jp/images/ph/ph_skip_reason_recipe_02.png?0222"
      />
    </Column>
  );
}
