"use client";
import { useCallback } from "react";

import clsx from "clsx";
import ReactPaginate from "react-paginate";

import styles from "./Pagination.module.scss";

type PaginationEvent = {
  selected: number;
};

interface PaginationProps {
  totalPage: number;
  onPageChange: (page: number) => void;
}

export function Pagination({ totalPage, onPageChange }: PaginationProps): React.ReactNode {
  const handlePageChange = useCallback(
    (event: PaginationEvent) => {
      onPageChange(event.selected + 1);
    },
    [onPageChange]
  );

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={<i className="fas fa-angle-right"></i>}
      previousLabel={<i className="fas fa-angle-left"></i>}
      onPageChange={handlePageChange}
      pageRangeDisplayed={1}
      /**
       * totalPageが1の時return nullすると、Paginationのページ数をクリックしても何も反応しないreact-paginateの潜在バグが発生するため、
       * totalPageが1の時はpageCountを0に設定してページネーションを表示しないようにする
       *
       * @see https://app.clickup.com/t/86eq3yr83
       */
      pageCount={totalPage === 1 ? 0 : totalPage}
      renderOnZeroPageCount={null}
      marginPagesDisplayed={2}
      className={styles.pagination}
      pageClassName={styles.page}
      pageLinkClassName={styles.pageLink}
      activeLinkClassName={styles.activeLink}
      previousClassName={clsx(styles.page, styles.previous)}
      nextClassName={clsx(styles.page, styles.next)}
      disabledLinkClassName={styles.disabled}
      breakClassName={clsx(styles.page)}
    />
  );
}
