import { Column, Row } from "@/components/containers";
import { MileLabelType } from "@/generated/open-api/schemas";
import { numberFormat } from "@/utils";

import { getMileHistoryTypeInfo } from "./helper";
import styles from "./MileHistoryItem.module.scss";

export interface MileHistoryItemProps {
  date: string;
  amount: number;
  description: string;
  label: MileLabelType;
}

export function MileHistoryItem({
  date,
  amount,
  description,
  label,
}: MileHistoryItemProps): React.ReactNode {
  const labelTypeInfo = getMileHistoryTypeInfo(label);

  return (
    <Row>
      <Column gap={10} flexFull>
        <div className={styles.date}>{date}</div>
        <div className={styles.description}>{description}</div>
      </Column>
      <Column className={labelTypeInfo.isAddition ? styles.additionItem : styles.subtractionItem}>
        <div className={styles.amount}>
          {labelTypeInfo.sign}
          {numberFormat(amount)}
        </div>
        <div className={styles.label}>{labelTypeInfo.typeText}</div>
      </Column>
    </Row>
  );
}
