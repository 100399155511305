"use client";

import { useCallback, useState } from "react";

import clsx from "clsx";
import { animateScroll } from "react-scroll";

import { Column, Row } from "@/components/containers";
import { Hr, LoadingOverlay, Paper } from "@/components/displays";
import { htmlToast } from "@/components/feedbacks";
import { usePostMileGiftsTrades } from "@/generated/open-api/mile/mile";
import { MileGiftsItemModel } from "@/models/mile/type";
import {
  useParsedGetMileGifts,
  useParsedGetMileGiftsTrades,
  useParsedGetMileSummary,
  useParsedGetSubscription,
} from "@/queries";
import { getErrorMessages } from "@/utils/error";

import { MileGiftItem } from "./MileGiftItem";
import styles from "./MileGifts.module.scss";
import { MileGiftTradeModal } from "../MileGiftTradeModal";
import { MileSummary } from "../MileSummary";

export function MileGifts(): React.ReactNode {
  const [isTradeModalOpen, setIsTradeModalOpen] = useState(false);
  const [selectedMileGift, setSelectedMileGift] = useState<MileGiftsItemModel>();
  const { data: mileSummary, refetch: refetchMileSummary } = useParsedGetMileSummary();
  const { data: mileGifts, isLoading: isFetchingMileGifts } = useParsedGetMileGifts();
  const { data } = useParsedGetSubscription();
  const hasSubscription = !!data?.subscription || !!data?.freezeSubscription;
  const { mutateAsync: tradeMileGift, isPending: isTradingMileGift } = usePostMileGiftsTrades();
  const {
    hasNormalGiftsTradesInProcess,
    hasFreezeGiftsTradesInProcess,
    refetch: refetchMileGiftsTrades,
  } = useParsedGetMileGiftsTrades();
  const anyGiftsTradesInProcess = hasNormalGiftsTradesInProcess || hasFreezeGiftsTradesInProcess;
  const availableMiles = mileSummary?.availableMiles ?? 0;

  const toggleTradeModal = useCallback(() => {
    setIsTradeModalOpen((prev) => !prev);
  }, []);

  const handleOpenTradeModal = useCallback(
    (id: number) => {
      const selectedGift = mileGifts?.find((mileGift) => mileGift.id === id);
      if (!selectedGift) {
        return;
      }
      setSelectedMileGift(selectedGift);
      toggleTradeModal();
    },
    [mileGifts, toggleTradeModal]
  );

  const handleTradeMileGift = useCallback(async () => {
    if (!selectedMileGift?.id) {
      return;
    }

    try {
      await tradeMileGift({ params: { id: selectedMileGift.id } });
      await refetchMileGiftsTrades();
      await refetchMileSummary();
      animateScroll.scrollToTop();
      toggleTradeModal();
    } catch (error) {
      htmlToast.error(getErrorMessages(error));
    }
  }, [
    refetchMileGiftsTrades,
    refetchMileSummary,
    selectedMileGift?.id,
    tradeMileGift,
    toggleTradeModal,
  ]);

  return (
    <>
      <MileSummary type="mileExchange" hideTotalMiles hideAnnotation hideTooltip />
      <Paper padding="responsive">
        <LoadingOverlay isLoading={isFetchingMileGifts}>
          <Column gap={20}>
            <div className="text__bold">交換できるギフト</div>
            <div
              className={clsx(
                styles.annotation,
                anyGiftsTradesInProcess ? "text__red text__bold" : "text__s text__gray__dark"
              )}
            >
              ギフト交換は、一注文につき一点までとさせていただいております。
            </div>
            <Row gap={20} flex="wrap">
              {mileGifts?.map((mileGift) => {
                /**
                 * 1. 交換可能なマイルが足りる
                 * 2. 常温ギフトの場合、常温ギフトの交換が進行中でない
                 * 3. 冷凍ギフトの場合、冷凍ギフトの交換が進行中でない
                 * 4. 有効なサブスクリプションがある
                 */
                const isExchangeable =
                  mileGift.point <= availableMiles &&
                  ((mileGift.isFreeze && !hasFreezeGiftsTradesInProcess) ||
                    (!mileGift.isFreeze && !hasNormalGiftsTradesInProcess)) &&
                  hasSubscription;
                return (
                  <Column gap={20} key={mileGift.id} className={styles.giftItem}>
                    <MileGiftItem
                      id={mileGift.id}
                      imageUrl={mileGift.imageUrl}
                      miles={mileGift.point}
                      name={mileGift.name}
                      isExchangeable={isExchangeable}
                      onExchange={handleOpenTradeModal}
                    />
                    <Hr noMargin />
                  </Column>
                );
              })}
            </Row>
            <div className={clsx(styles.annotation, "text__s", "text__gray__dark")}>
              {`※ご利用中の継続コースが常温配送か冷凍配送かによって、交換可能なギフトが異なります。
※交換したギフトは、次回の継続コースに同梱してお届けします。`}
            </div>
          </Column>
          <MileGiftTradeModal
            type="trade"
            open={isTradeModalOpen}
            giftTitle={selectedMileGift?.name || ""}
            giftDescription={selectedMileGift?.description || ""}
            giftImageUrl={selectedMileGift?.imageUrl || ""}
            beforePrice={availableMiles}
            afterPrice={availableMiles - (selectedMileGift?.point || 0)}
            onNegative={toggleTradeModal}
            onPositive={handleTradeMileGift}
            isOperationPending={isTradingMileGift}
          />
        </LoadingOverlay>
      </Paper>
    </>
  );
}
