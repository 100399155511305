import { Row } from "@/components/containers";
import { Button } from "@/components/inputs";

import { MyPageModal } from "../../MyPageModal";

export interface MyPageDiscardConfirmModalProps {
  open: boolean;
  onClose: () => void;
  onDiscard: () => void;
}

export function MyPageDiscardConfirmModal({
  open,
  onClose,
  onDiscard,
}: MyPageDiscardConfirmModalProps): React.ReactNode {
  return (
    <MyPageModal
      open={open}
      closeModal={onClose}
      footer={
        <Row gap={10}>
          <Button onClick={onClose} variants="gray">
            キャンセル
          </Button>
          <Button onClick={onDiscard} variants={"yellow"}>
            破棄
          </Button>
        </Row>
      }
    >
      <p className="pd__top__m">保存されていない変更を破棄してもよろしいですか？</p>
    </MyPageModal>
  );
}
