"use client";

import clsx from "clsx";
import { usePathname } from "next/navigation";

import { LinkConfirm } from "@/components/displays";
import { Button } from "@/components/inputs";
import { getIsAppWebView } from "@/utils";
import { useMyPagePathInfo } from "@/utils/hooks";

import styles from "./MyPageFooter.module.scss";
import { LogoutButton } from "../MyPageIndex/LogoutButton";

const hasFixedFooter = (pathname: string) => {
  // 継続管理コース画面にfixedフッターがある
  return ["/mypage/subscription", "/mypage/freeze_subscription"].some((path) =>
    pathname.startsWith(path)
  );
};

export function MyPageFooter(): React.ReactNode {
  const { isRootPage, parentPath, parentPathName } = useMyPagePathInfo();
  const pathname = usePathname();
  const isAppWebView = getIsAppWebView();
  // ルートページでないあるいはアプリWebViewでない場合のみフッターを表示
  const displayFooterWrapper = !isRootPage || !isAppWebView;
  // 継続管理コース画面にfixedフッターがあるため、padding-bottomを追加する
  const addSpecialPaddingBottom = isAppWebView && hasFixedFooter(pathname);
  // ルートページかつアプリWebViewでない場合のみログアウトボタンを表示
  const displayLogoutButton = isRootPage && !isAppWebView;
  // ルートページでない場合のみ戻るボタンを表示
  const displayBackButton = !isRootPage;

  return (
    <div
      className={clsx(
        displayFooterWrapper && styles.root,
        addSpecialPaddingBottom && styles.specialPadding
      )}
    >
      {displayLogoutButton && <LogoutButton />}
      {displayBackButton && (
        <LinkConfirm href={parentPath}>
          <Button className={styles.backButton} variants="outlined" rounded>
            <i className={clsx("fas", "fa-angle-left", styles.buttonStartIcon)} />
            {parentPathName}に戻る
          </Button>
        </LinkConfirm>
      )}
    </div>
  );
}
