"use client";
import { useCallback, useEffect, useMemo, useState } from "react";

import { CartModel } from "@/models/cart/type";
import { useClientFrontStoreCartItems } from "@/storage";

type SubscriptionStatusProps = {
  canSubscribe: boolean; // 対象商品が定期購入対象かどうか
  cart: CartModel | undefined;
};

// 対象商品の定期購入の状態管理
export const useSubscriptionStatus = ({ canSubscribe, cart }: SubscriptionStatusProps) => {
  // 対象商品を定期商品設定にするかどうか
  const [subscriptionEnabled, setSubscriptionEnabled] = useState(canSubscribe);
  // カート内の他の商品が定期購入対象かどうか
  const [canSubscribeOtherInCart, setCanSubscribeOtherInCart] = useState(true);
  const { cartItems } = useClientFrontStoreCartItems();

  const existProduct = useMemo(
    () => cartItems.filter((item) => item.quantity > 0).length > 0,
    [cartItems]
  );
  const existSubscription = useMemo(
    () => cartItems.some((item) => item.subscription && item.quantity > 0),
    [cartItems]
  );

  const setSubscriptionStatus = useCallback(async () => {
    if (existProduct) {
      setSubscriptionEnabled(existSubscription);

      // 定期購入できない商品がカートに入っているかどうか
      if (!existSubscription) {
        setCanSubscribeOtherInCart(!cart?.hasNoSubscriptionProduct);
      }
    }
  }, [existProduct, existSubscription, cart]);

  useEffect(() => {
    setSubscriptionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    subscriptionEnabled,
    setSubscriptionEnabled,
    canSubscribeOtherInCart,
  };
};
