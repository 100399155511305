export interface PurchaseHistoryDataEmptyBodyProps {
  inTable?: boolean;
  colSpan?: number;
}

export function PurchaseHistoryDataEmptyBody({
  inTable = true,
  colSpan,
}: PurchaseHistoryDataEmptyBodyProps): React.ReactNode {
  const contents = (
    <>
      <p className="text__m mg__top__s">注文履歴はございません。</p>
      <p className="text__m mg__top__s">
        すでにご注文いただいているかたは他にアカウントをお持ちの可能性がございます。
        <a href="https://basefood.zendesk.com/hc/ja/articles/900004883446" target="_blank">
          こちら
        </a>
        をご覧ください。
      </p>
    </>
  );

  return inTable ? (
    <tr>
      <td colSpan={colSpan} className="text__center">
        {contents}
      </td>
    </tr>
  ) : (
    contents
  );
}
