"use client";

import { MileSummary } from "@/components/domains/mile";
import { completeAnalytics } from "@/components/template/mypage/helpers";
import { loginLog } from "@/generated/axios-functions/payseAPI";
import { NewsPostModel } from "@/models/newsPost/type";
import {
  useParsedGetCustomer,
  useParsedGetMiles,
  useParsedGetPoints,
  useParsedGetSubscription,
} from "@/queries";
import { pushGtmEvent } from "@/utils/gtm";
import { useAsync } from "@/utils/hooks";

import { Announcements } from "./Announcements";
import { CustomerBox } from "./CustomerBox";
import { DeliverySchedule } from "./DeliverySchedule";
import { DeliveryStatus } from "./DeliveryStatus";
import { useAppWebView, useDeliveryDate } from "./helpers";
import styles from "./MyPageIndex.module.scss";
import { NewsSection } from "./NewsSection";
import { OnlineCommunity } from "./OnlineCommunity";
import { PointBox } from "./PointBox";
import { StatusBox } from "./StatusBox";

interface MyPageIndexProps {
  newsPosts: NewsPostModel[];
}

export function MyPageIndex({ newsPosts }: MyPageIndexProps): React.ReactNode {
  const { data: customer } = useParsedGetCustomer();
  const { data: subscription } = useParsedGetSubscription();
  const { data: mile } = useParsedGetMiles();
  const { data: points } = useParsedGetPoints();
  const { normalDeliveryDate, freezeDeliveryDate } = useDeliveryDate(subscription);
  useAppWebView();

  useAsync(async () => {
    pushGtmEvent({ event: "optimize.activate" });
    await loginLog();
    await completeAnalytics(customer, subscription, mile, points.total);
  }, []);

  return (
    <>
      <div className={styles.pcSection}>
        <DeliveryStatus
          subscription={subscription}
          normalDeliveryDate={normalDeliveryDate}
          freezeDeliveryDate={freezeDeliveryDate}
        />

        {mile && <StatusBox mile={mile} subscription={subscription} />}
        <MileSummary type="mypage" hideAnnotation hideTotalMiles />
        <PointBox />

        <div className="clear__sp">
          <Announcements />
        </div>
      </div>

      <div id="schedule" className={styles.pcSection}>
        <DeliverySchedule />
        <CustomerBox />

        <div className="clear__pc">
          <Announcements />
        </div>

        <OnlineCommunity />
        <NewsSection newsPosts={newsPosts} />
      </div>
    </>
  );
}
