"use client";

import { useCallback, useState } from "react";

import { saveSurvey } from "@/generated/axios-functions/payseAPI";
import { useClientCartOrder } from "@/storage";

import {
  SurveyAnswers,
  SurveyForm,
  SurveyFormProps,
  covertToApiSurveyAnswers,
} from "../SurveyForm";

export interface AfterPurchaseSurveyProps {
  questions: SurveyFormProps["questions"];
}

export function AfterPurchaseSurvey({ questions }: AfterPurchaseSurveyProps): React.ReactNode {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [answers, setAnswers] = useState<SurveyAnswers>({});
  const { order } = useClientCartOrder();

  const handleSendSurveyAnswers = useCallback(() => {
    // 送信処理
    setIsSubmitted(true);

    saveSurvey({
      answers: covertToApiSurveyAnswers(answers),
      email: order?.email || "",
    });
  }, [answers, order?.email]);

  return isSubmitted ? (
    <div>アンケートにご協力ありがとうございました。</div>
  ) : (
    <>
      <SurveyForm onChange={setAnswers} questions={questions} />
      <div className="text__center">
        <button
          type="button"
          className="btn inline yellow round angle__right text__black text__m"
          onClick={handleSendSurveyAnswers}
        >
          回答する
        </button>
      </div>
    </>
  );
}
